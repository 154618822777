/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNotificationCount
// ====================================================

export interface GetNotificationCount_me {
  __typename: "User";
  id: string;
  notificationCount: number;
}

export interface GetNotificationCount {
  /**
   *  Get the current user 
   */
  me: GetNotificationCount_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEmployees
// ====================================================

export interface GetEmployees_employees_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of total available items.
   */
  total: number;
  /**
   * Index of the last available page.
   */
  lastPage: number;
  /**
   * Index of the current page.
   */
  currentPage: number;
}

export interface GetEmployees_employees_data_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetEmployees_employees_data_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetEmployees_employees_data_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetEmployees_employees_data_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetEmployees_employees_data_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetEmployees_employees_data_functions_code;
  description: string;
  startedAt: any;
}

export interface GetEmployees_employees_data_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetEmployees_employees_data_departments_department {
  __typename: "Department";
  id: string;
  area: GetEmployees_employees_data_departments_department_area | null;
}

export interface GetEmployees_employees_data_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetEmployees_employees_data_departments_department;
}

export interface GetEmployees_employees_data_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetEmployees_employees_data_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetEmployees_employees_data_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetEmployees_employees_data_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetEmployees_employees_data_remarks_createdUser;
}

export interface GetEmployees_employees_data_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetEmployees_employees_data_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetEmployees_employees_data_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetEmployees_employees_data_currentHotelReservation_bed_room_section_hotel;
}

export interface GetEmployees_employees_data_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetEmployees_employees_data_currentHotelReservation_bed_room_section;
}

export interface GetEmployees_employees_data_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetEmployees_employees_data_currentHotelReservation_bed_room;
}

export interface GetEmployees_employees_data_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetEmployees_employees_data_currentHotelReservation_bed;
}

export interface GetEmployees_employees_data_employments_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetEmployees_employees_data_employments_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetEmployees_employees_data_employments_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetEmployees_employees_data_employments_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetEmployees_employees_data_employments_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetEmployees_employees_data_employments_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetEmployees_employees_data_employments_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetEmployees_employees_data_employments_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetEmployees_employees_data_employments_employee_departments_department_area | null;
}

export interface GetEmployees_employees_data_employments_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetEmployees_employees_data_employments_employee_departments_department;
}

export interface GetEmployees_employees_data_employments_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetEmployees_employees_data_employments_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetEmployees_employees_data_employments_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetEmployees_employees_data_employments_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetEmployees_employees_data_employments_employee_remarks_createdUser;
}

export interface GetEmployees_employees_data_employments_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetEmployees_employees_data_employments_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetEmployees_employees_data_employments_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetEmployees_employees_data_employments_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetEmployees_employees_data_employments_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetEmployees_employees_data_employments_employee_currentHotelReservation_bed_room_section;
}

export interface GetEmployees_employees_data_employments_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetEmployees_employees_data_employments_employee_currentHotelReservation_bed_room;
}

export interface GetEmployees_employees_data_employments_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetEmployees_employees_data_employments_employee_currentHotelReservation_bed;
}

export interface GetEmployees_employees_data_employments_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetEmployees_employees_data_employments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetEmployees_employees_data_employments_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetEmployees_employees_data_employments_employee_currentFunction | null;
  functions: GetEmployees_employees_data_employments_employee_functions[];
  departments: GetEmployees_employees_data_employments_employee_departments[];
  absences: GetEmployees_employees_data_employments_employee_absences[];
  leaves: GetEmployees_employees_data_employments_employee_leaves[];
  remarks: GetEmployees_employees_data_employments_employee_remarks[];
  hotelReservations: GetEmployees_employees_data_employments_employee_hotelReservations[];
  currentHotelReservation: GetEmployees_employees_data_employments_employee_currentHotelReservation | null;
  employments: GetEmployees_employees_data_employments_employee_employments[];
  tempAgency: GetEmployees_employees_data_employments_employee_tempAgency | null;
}

export interface GetEmployees_employees_data_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetEmployees_employees_data_employments_employee;
}

export interface GetEmployees_employees_data_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetEmployees_employees_data {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetEmployees_employees_data_currentFunction | null;
  functions: GetEmployees_employees_data_functions[];
  departments: GetEmployees_employees_data_departments[];
  absences: GetEmployees_employees_data_absences[];
  leaves: GetEmployees_employees_data_leaves[];
  remarks: GetEmployees_employees_data_remarks[];
  hotelReservations: GetEmployees_employees_data_hotelReservations[];
  currentHotelReservation: GetEmployees_employees_data_currentHotelReservation | null;
  employments: GetEmployees_employees_data_employments[];
  tempAgency: GetEmployees_employees_data_tempAgency | null;
}

export interface GetEmployees_employees {
  __typename: "EmployeePaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: GetEmployees_employees_paginatorInfo;
  /**
   * A list of Employee items.
   */
  data: GetEmployees_employees_data[];
}

export interface GetEmployees {
  /**
   *  Paginate employees 
   */
  employees: GetEmployees_employees | null;
}

export interface GetEmployeesVariables {
  areaId?: string | null;
  search: string;
  page: number;
  currentlyEmployed?: boolean | null;
  hasActiveLeave?: boolean | null;
  orderBy?: QueryEmployeesOrderByOrderByClause[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterEmployeeRemark
// ====================================================

export interface AlterEmployeeRemark_alterEmployeeRemark_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface AlterEmployeeRemark_alterEmployeeRemark_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: AlterEmployeeRemark_alterEmployeeRemark_employee_remarks_createdUser;
}

export interface AlterEmployeeRemark_alterEmployeeRemark_employee {
  __typename: "Employee";
  id: string;
  name: string;
  remarks: AlterEmployeeRemark_alterEmployeeRemark_employee_remarks[];
}

export interface AlterEmployeeRemark_alterEmployeeRemark {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  employee: AlterEmployeeRemark_alterEmployeeRemark_employee;
}

export interface AlterEmployeeRemark {
  alterEmployeeRemark: AlterEmployeeRemark_alterEmployeeRemark | null;
}

export interface AlterEmployeeRemarkVariables {
  id?: string | null;
  employeeId: string;
  remark: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleEmployeeMark
// ====================================================

export interface ToggleEmployeeMark_toggleEmployeeMark {
  __typename: "Employee";
  id: string;
  name: string;
  marked: boolean;
}

export interface ToggleEmployeeMark {
  toggleEmployeeMark: ToggleEmployeeMark_toggleEmployeeMark | null;
}

export interface ToggleEmployeeMarkVariables {
  employeeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleEmployeeHousing
// ====================================================

export interface ToggleEmployeeHousing_toggleEmployeeHousing {
  __typename: "Employee";
  id: string;
  name: string;
  needsHousing: boolean;
}

export interface ToggleEmployeeHousing {
  toggleEmployeeHousing: ToggleEmployeeHousing_toggleEmployeeHousing;
}

export interface ToggleEmployeeHousingVariables {
  employeeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteWeekTempEmployment
// ====================================================

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_departments_department {
  __typename: "Department";
  id: string;
  area: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_departments_department_area | null;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_departments_department;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_remarks_createdUser;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation_bed_room_section;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation_bed_room;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation_bed;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentFunction | null;
  functions: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_functions[];
  departments: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_departments[];
  absences: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_absences[];
  leaves: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_leaves[];
  remarks: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_remarks[];
  hotelReservations: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_hotelReservations[];
  currentHotelReservation: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_currentHotelReservation | null;
  employments: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_employments[];
  tempAgency: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee_tempAgency | null;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments_employee;
}

export interface DeleteWeekTempEmployment_deleteWeekTempEmployment {
  __typename: "Week";
  id: string;
  hoursSupply: number;
  tempEmployments: DeleteWeekTempEmployment_deleteWeekTempEmployment_tempEmployments[];
}

export interface DeleteWeekTempEmployment {
  /**
   *  Deletes the provided temporary employment 
   */
  deleteWeekTempEmployment: DeleteWeekTempEmployment_deleteWeekTempEmployment;
}

export interface DeleteWeekTempEmploymentVariables {
  weekId: string;
  employmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UndoWeekException
// ====================================================

export interface UndoWeekException_undoWeekException_transferredIn_weekFrom_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface UndoWeekException_undoWeekException_transferredIn_weekFrom {
  __typename: "Week";
  id: string;
  area: UndoWeekException_undoWeekException_transferredIn_weekFrom_area;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: UndoWeekException_undoWeekException_transferredIn_employment_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: UndoWeekException_undoWeekException_transferredIn_employment_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_departments_department {
  __typename: "Department";
  id: string;
  area: UndoWeekException_undoWeekException_transferredIn_employment_employee_departments_department_area | null;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: UndoWeekException_undoWeekException_transferredIn_employment_employee_departments_department;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: UndoWeekException_undoWeekException_transferredIn_employment_employee_remarks_createdUser;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation_bed_room_section;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation_bed_room;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation_bed;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: UndoWeekException_undoWeekException_transferredIn_employment_employee_currentFunction | null;
  functions: UndoWeekException_undoWeekException_transferredIn_employment_employee_functions[];
  departments: UndoWeekException_undoWeekException_transferredIn_employment_employee_departments[];
  absences: UndoWeekException_undoWeekException_transferredIn_employment_employee_absences[];
  leaves: UndoWeekException_undoWeekException_transferredIn_employment_employee_leaves[];
  remarks: UndoWeekException_undoWeekException_transferredIn_employment_employee_remarks[];
  hotelReservations: UndoWeekException_undoWeekException_transferredIn_employment_employee_hotelReservations[];
  currentHotelReservation: UndoWeekException_undoWeekException_transferredIn_employment_employee_currentHotelReservation | null;
  employments: UndoWeekException_undoWeekException_transferredIn_employment_employee_employments[];
  tempAgency: UndoWeekException_undoWeekException_transferredIn_employment_employee_tempAgency | null;
}

export interface UndoWeekException_undoWeekException_transferredIn_employment {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: UndoWeekException_undoWeekException_transferredIn_employment_employee;
}

export interface UndoWeekException_undoWeekException_transferredIn {
  __typename: "WeekException";
  id: string;
  weekFrom: UndoWeekException_undoWeekException_transferredIn_weekFrom;
  employment: UndoWeekException_undoWeekException_transferredIn_employment;
}

export interface UndoWeekException_undoWeekException_transferredOut_weekTo_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface UndoWeekException_undoWeekException_transferredOut_weekTo {
  __typename: "Week";
  id: string;
  area: UndoWeekException_undoWeekException_transferredOut_weekTo_area;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: UndoWeekException_undoWeekException_transferredOut_employment_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: UndoWeekException_undoWeekException_transferredOut_employment_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_departments_department {
  __typename: "Department";
  id: string;
  area: UndoWeekException_undoWeekException_transferredOut_employment_employee_departments_department_area | null;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: UndoWeekException_undoWeekException_transferredOut_employment_employee_departments_department;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: UndoWeekException_undoWeekException_transferredOut_employment_employee_remarks_createdUser;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation_bed_room_section;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation_bed_room;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation_bed;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: UndoWeekException_undoWeekException_transferredOut_employment_employee_currentFunction | null;
  functions: UndoWeekException_undoWeekException_transferredOut_employment_employee_functions[];
  departments: UndoWeekException_undoWeekException_transferredOut_employment_employee_departments[];
  absences: UndoWeekException_undoWeekException_transferredOut_employment_employee_absences[];
  leaves: UndoWeekException_undoWeekException_transferredOut_employment_employee_leaves[];
  remarks: UndoWeekException_undoWeekException_transferredOut_employment_employee_remarks[];
  hotelReservations: UndoWeekException_undoWeekException_transferredOut_employment_employee_hotelReservations[];
  currentHotelReservation: UndoWeekException_undoWeekException_transferredOut_employment_employee_currentHotelReservation | null;
  employments: UndoWeekException_undoWeekException_transferredOut_employment_employee_employments[];
  tempAgency: UndoWeekException_undoWeekException_transferredOut_employment_employee_tempAgency | null;
}

export interface UndoWeekException_undoWeekException_transferredOut_employment {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: UndoWeekException_undoWeekException_transferredOut_employment_employee;
}

export interface UndoWeekException_undoWeekException_transferredOut {
  __typename: "WeekException";
  id: string;
  weekTo: UndoWeekException_undoWeekException_transferredOut_weekTo;
  employment: UndoWeekException_undoWeekException_transferredOut_employment;
}

export interface UndoWeekException_undoWeekException_logs {
  __typename: "WeekLog";
  id: string;
  type: string;
  hours: number;
  comment: string;
  createdAt: any;
}

export interface UndoWeekException_undoWeekException {
  __typename: "Week";
  id: string;
  number: number;
  year: number;
  hoursSupply: number;
  hoursDemand: number;
  hoursDemandCosts: number;
  hoursFTE: number;
  budget: number;
  transferredIn: UndoWeekException_undoWeekException_transferredIn[];
  transferredOut: UndoWeekException_undoWeekException_transferredOut[];
  logs: UndoWeekException_undoWeekException_logs[];
}

export interface UndoWeekException {
  undoWeekException: UndoWeekException_undoWeekException[];
}

export interface UndoWeekExceptionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteEmployeeRemark
// ====================================================

export interface DeleteEmployeeRemark_deleteEmployeeRemark {
  __typename: "EmployeeRemark";
  id: string;
}

export interface DeleteEmployeeRemark {
  deleteEmployeeRemark: DeleteEmployeeRemark_deleteEmployeeRemark | null;
}

export interface DeleteEmployeeRemarkVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetForecastingOverview
// ====================================================

export interface GetForecastingOverview_forecastingOverview_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetForecastingOverview_forecastingOverview_weeks {
  __typename: "ForecastOverviewWeek";
  week: string;
  forecastProvided: boolean;
}

export interface GetForecastingOverview_forecastingOverview {
  __typename: "ForecastOverview";
  area: GetForecastingOverview_forecastingOverview_area;
  weeks: GetForecastingOverview_forecastingOverview_weeks[];
}

export interface GetForecastingOverview {
  forecastingOverview: GetForecastingOverview_forecastingOverview[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWeekForecast
// ====================================================

export interface GetWeekForecast_weekById_cultivationTypeActivities_type {
  __typename: "CultivationType";
  id: string;
  name: string;
}

export interface GetWeekForecast_weekById_cultivationTypeActivities_activity {
  __typename: "CultivationActivity";
  id: string;
  name: string;
  unitType: number;
  unitTypeDescription: string;
}

export interface GetWeekForecast_weekById_cultivationTypeActivities_pivot {
  __typename: "CultivationTypeActivityPivot";
  norm: number | null;
  normUnit: string | null;
  normUnitDescription: string | null;
}

export interface GetWeekForecast_weekById_cultivationTypeActivities {
  __typename: "CultivationTypeActivity";
  id: string;
  type: GetWeekForecast_weekById_cultivationTypeActivities_type;
  activity: GetWeekForecast_weekById_cultivationTypeActivities_activity;
  pivot: GetWeekForecast_weekById_cultivationTypeActivities_pivot | null;
}

export interface GetWeekForecast_weekById_forecasts_estimates_activity {
  __typename: "CultivationTypeActivity";
  id: string;
}

export interface GetWeekForecast_weekById_forecasts_estimates {
  __typename: "WeekForecastEstimate";
  id: string;
  amount: number;
  norm: number;
  estimateMonday: number;
  estimateTuesday: number;
  estimateWednesday: number;
  estimateThursday: number;
  estimateFriday: number;
  estimateSaturday: number;
  estimateSunday: number;
  comments: string | null;
  activity: GetWeekForecast_weekById_forecasts_estimates_activity;
}

export interface GetWeekForecast_weekById_forecasts_user {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekForecast_weekById_forecasts {
  __typename: "WeekForecast";
  id: string;
  state: WeekForecastState;
  totalHours: number;
  estimates: GetWeekForecast_weekById_forecasts_estimates[];
  user: GetWeekForecast_weekById_forecasts_user;
  createdAt: any;
  updatedAt: any;
}

export interface GetWeekForecast_weekById {
  __typename: "Week";
  id: string;
  year: number;
  number: number;
  hoursDemand: number;
  hoursDemandCosts: number;
  hasOpenForecastingTasks: boolean;
  cultivationTypeActivities: GetWeekForecast_weekById_cultivationTypeActivities[];
  forecasts: GetWeekForecast_weekById_forecasts[];
}

export interface GetWeekForecast {
  /**
   *  Get a specific week 
   */
  weekById: GetWeekForecast_weekById | null;
}

export interface GetWeekForecastVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetWeekForecastNotifications
// ====================================================

export interface ResetWeekForecastNotifications_resetWeekForecastNotifications {
  __typename: "Week";
  id: string;
}

export interface ResetWeekForecastNotifications {
  /**
   * Reset the forecast notifications for the provided week.
   */
  resetWeekForecastNotifications: ResetWeekForecastNotifications_resetWeekForecastNotifications;
}

export interface ResetWeekForecastNotificationsVariables {
  weekId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertForecastEstimations
// ====================================================

export interface UpsertForecastEstimations_upsertForecastEstimations_estimates {
  __typename: "WeekForecastEstimate";
  id: string;
  amount: number;
  norm: number;
  estimateMonday: number;
  estimateTuesday: number;
  estimateWednesday: number;
  estimateThursday: number;
  estimateFriday: number;
  estimateSaturday: number;
  estimateSunday: number;
  comments: string | null;
}

export interface UpsertForecastEstimations_upsertForecastEstimations {
  __typename: "WeekForecast";
  id: string;
  state: WeekForecastState;
  estimates: UpsertForecastEstimations_upsertForecastEstimations_estimates[];
}

export interface UpsertForecastEstimations {
  /**
   * Upsert the forecast estimations for the provided week and state.
   */
  upsertForecastEstimations: UpsertForecastEstimations_upsertForecastEstimations;
}

export interface UpsertForecastEstimationsVariables {
  input: ForecastEstimationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHotelSection
// ====================================================

export interface GetHotelSection_hotelSection_hotel_sections_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetHotelSection_hotelSection_hotel_sections {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetHotelSection_hotelSection_hotel_sections_hotel;
}

export interface GetHotelSection_hotelSection_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
  sections: GetHotelSection_hotelSection_hotel_sections[];
}

export interface GetHotelSection_hotelSection_rooms_beds_room {
  __typename: "HotelRoom";
  id: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_departments_department_area | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_departments_department;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_remarks_createdUser;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation_bed_room_section;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation_bed_room;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation_bed;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_departments_department_area | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_departments_department;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_remarks_createdUser;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation_bed_room_section;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation_bed_room;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation_bed;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentFunction | null;
  functions: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_functions[];
  departments: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_departments[];
  absences: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_absences[];
  leaves: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_leaves[];
  remarks: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_remarks[];
  hotelReservations: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_hotelReservations[];
  currentHotelReservation: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_currentHotelReservation | null;
  employments: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_employments[];
  tempAgency: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee_tempAgency | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments_employee;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentFunction | null;
  functions: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_functions[];
  departments: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_departments[];
  absences: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_absences[];
  leaves: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_leaves[];
  remarks: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_remarks[];
  hotelReservations: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_hotelReservations[];
  currentHotelReservation: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_currentHotelReservation | null;
  employments: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_employments[];
  tempAgency: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee_tempAgency | null;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations_data {
  __typename: "HotelReservation";
  id: string;
  state: HotelReservationState;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  employee: GetHotelSection_hotelSection_rooms_beds_reservations_data_employee;
}

export interface GetHotelSection_hotelSection_rooms_beds_reservations {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetHotelSection_hotelSection_rooms_beds_reservations_data[];
}

export interface GetHotelSection_hotelSection_rooms_beds {
  __typename: "HotelBed";
  id: string;
  createdAt: any;
  deletedAt: any | null;
  number: number;
  room: GetHotelSection_hotelSection_rooms_beds_room;
  reservations: GetHotelSection_hotelSection_rooms_beds_reservations | null;
}

export interface GetHotelSection_hotelSection_rooms_remarks_createdBy {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetHotelSection_hotelSection_rooms_remarks_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
}

export interface GetHotelSection_hotelSection_rooms_remarks_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetHotelSection_hotelSection_rooms_remarks_room_section;
}

export interface GetHotelSection_hotelSection_rooms_remarks {
  __typename: "HotelRoomRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdBy: GetHotelSection_hotelSection_rooms_remarks_createdBy;
  room: GetHotelSection_hotelSection_rooms_remarks_room;
}

export interface GetHotelSection_hotelSection_rooms_section {
  __typename: "HotelSection";
  id: string;
  name: string;
}

export interface GetHotelSection_hotelSection_rooms_category_rate {
  __typename: "HotelRoomRates";
  id: string;
  amount: number;
}

export interface GetHotelSection_hotelSection_rooms_category {
  __typename: "HotelRoomCategory";
  id: string;
  name: string;
  rate: GetHotelSection_hotelSection_rooms_category_rate;
}

export interface GetHotelSection_hotelSection_rooms {
  __typename: "HotelRoom";
  id: string;
  name: string;
  beds: GetHotelSection_hotelSection_rooms_beds[];
  remarks: GetHotelSection_hotelSection_rooms_remarks[];
  section: GetHotelSection_hotelSection_rooms_section;
  category: GetHotelSection_hotelSection_rooms_category;
}

export interface GetHotelSection_hotelSection {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetHotelSection_hotelSection_hotel;
  rooms: GetHotelSection_hotelSection_rooms[];
}

export interface GetHotelSection {
  /**
   * Fetch a single hotel section
   */
  hotelSection: GetHotelSection_hotelSection;
}

export interface GetHotelSectionVariables {
  id: string;
  start: any;
  end: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHotelRoom
// ====================================================

export interface CreateHotelRoom_createHotelRoom_remarks_createdBy {
  __typename: "User";
  id: string;
  name: string;
}

export interface CreateHotelRoom_createHotelRoom_remarks_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
}

export interface CreateHotelRoom_createHotelRoom_remarks_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: CreateHotelRoom_createHotelRoom_remarks_room_section;
}

export interface CreateHotelRoom_createHotelRoom_remarks {
  __typename: "HotelRoomRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdBy: CreateHotelRoom_createHotelRoom_remarks_createdBy;
  room: CreateHotelRoom_createHotelRoom_remarks_room;
}

export interface CreateHotelRoom_createHotelRoom {
  __typename: "HotelRoom";
  id: string;
  name: string;
  remarks: CreateHotelRoom_createHotelRoom_remarks[];
}

export interface CreateHotelRoom {
  /**
   * Create a hotel room
   */
  createHotelRoom: CreateHotelRoom_createHotelRoom | null;
}

export interface CreateHotelRoomVariables {
  input: HotelRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHotelBed
// ====================================================

export interface CreateHotelBed_createHotelBed {
  __typename: "HotelBed";
  id: string;
}

export interface CreateHotelBed {
  /**
   * Create a hotel bed
   */
  createHotelBed: CreateHotelBed_createHotelBed | null;
}

export interface CreateHotelBedVariables {
  input: HotelBedInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHotelBed
// ====================================================

export interface DeleteHotelBed_deleteHotelBed {
  __typename: "HotelBed";
  id: string;
}

export interface DeleteHotelBed {
  /**
   * Delete a hotel bed
   */
  deleteHotelBed: DeleteHotelBed_deleteHotelBed | null;
}

export interface DeleteHotelBedVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHotelReservationCosts
// ====================================================

export interface GetHotelReservationCosts_hotelReservations_data_employee_tempAgency {
  __typename: "TempAgency";
  name: string;
}

export interface GetHotelReservationCosts_hotelReservations_data_employee_company {
  __typename: "Company";
  id: string;
  name: string | null;
}

export interface GetHotelReservationCosts_hotelReservations_data_employee {
  __typename: "Employee";
  id: string;
  number: number;
  name: string;
  costCenter: EmployeeCostCenter | null;
  tempAgency: GetHotelReservationCosts_hotelReservations_data_employee_tempAgency | null;
  company: GetHotelReservationCosts_hotelReservations_data_employee_company | null;
}

export interface GetHotelReservationCosts_hotelReservations_data_bed_room_section_hotel_touristTaxRates {
  __typename: "HotelTouristTaxRate";
  id: string;
  from: any;
  amount: number;
}

export interface GetHotelReservationCosts_hotelReservations_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
  touristTaxRates: GetHotelReservationCosts_hotelReservations_data_bed_room_section_hotel_touristTaxRates[];
}

export interface GetHotelReservationCosts_hotelReservations_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetHotelReservationCosts_hotelReservations_data_bed_room_section_hotel;
}

export interface GetHotelReservationCosts_hotelReservations_data_bed_room_category_rates {
  __typename: "HotelRoomRates";
  id: string;
  from: any;
  amount: number;
}

export interface GetHotelReservationCosts_hotelReservations_data_bed_room_category {
  __typename: "HotelRoomCategory";
  id: string;
  rates: GetHotelReservationCosts_hotelReservations_data_bed_room_category_rates[];
}

export interface GetHotelReservationCosts_hotelReservations_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetHotelReservationCosts_hotelReservations_data_bed_room_section;
  category: GetHotelReservationCosts_hotelReservations_data_bed_room_category;
}

export interface GetHotelReservationCosts_hotelReservations_data_bed {
  __typename: "HotelBed";
  id: string;
  number: number;
  room: GetHotelReservationCosts_hotelReservations_data_bed_room;
}

export interface GetHotelReservationCosts_hotelReservations_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  employee: GetHotelReservationCosts_hotelReservations_data_employee;
  bed: GetHotelReservationCosts_hotelReservations_data_bed;
}

export interface GetHotelReservationCosts_hotelReservations {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetHotelReservationCosts_hotelReservations_data[];
}

export interface GetHotelReservationCosts {
  /**
   * Fetch all reservations adhering to provided criteria
   */
  hotelReservations: GetHotelReservationCosts_hotelReservations | null;
}

export interface GetHotelReservationCostsVariables {
  from: any;
  until: any;
  hotel: string;
  section?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCheckInOut
// ====================================================

export interface GetCheckInOut_checkIn_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetCheckInOut_checkIn_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetCheckInOut_checkIn_data_bed_room_section_hotel;
}

export interface GetCheckInOut_checkIn_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetCheckInOut_checkIn_data_bed_room_section;
}

export interface GetCheckInOut_checkIn_data_bed {
  __typename: "HotelBed";
  id: string;
  room: GetCheckInOut_checkIn_data_bed_room;
}

export interface GetCheckInOut_checkIn_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface GetCheckInOut_checkIn_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: GetCheckInOut_checkIn_data_bed;
  employee: GetCheckInOut_checkIn_data_employee;
}

export interface GetCheckInOut_checkIn {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetCheckInOut_checkIn_data[];
}

export interface GetCheckInOut_checkOut_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetCheckInOut_checkOut_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetCheckInOut_checkOut_data_bed_room_section_hotel;
}

export interface GetCheckInOut_checkOut_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetCheckInOut_checkOut_data_bed_room_section;
}

export interface GetCheckInOut_checkOut_data_bed {
  __typename: "HotelBed";
  id: string;
  room: GetCheckInOut_checkOut_data_bed_room;
}

export interface GetCheckInOut_checkOut_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface GetCheckInOut_checkOut_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: GetCheckInOut_checkOut_data_bed;
  employee: GetCheckInOut_checkOut_data_employee;
}

export interface GetCheckInOut_checkOut {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetCheckInOut_checkOut_data[];
}

export interface GetCheckInOut_futureCheckIn_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetCheckInOut_futureCheckIn_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetCheckInOut_futureCheckIn_data_bed_room_section_hotel;
}

export interface GetCheckInOut_futureCheckIn_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetCheckInOut_futureCheckIn_data_bed_room_section;
}

export interface GetCheckInOut_futureCheckIn_data_bed {
  __typename: "HotelBed";
  id: string;
  room: GetCheckInOut_futureCheckIn_data_bed_room;
}

export interface GetCheckInOut_futureCheckIn_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface GetCheckInOut_futureCheckIn_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: GetCheckInOut_futureCheckIn_data_bed;
  employee: GetCheckInOut_futureCheckIn_data_employee;
}

export interface GetCheckInOut_futureCheckIn {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetCheckInOut_futureCheckIn_data[];
}

export interface GetCheckInOut_futureCheckOut_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetCheckInOut_futureCheckOut_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetCheckInOut_futureCheckOut_data_bed_room_section_hotel;
}

export interface GetCheckInOut_futureCheckOut_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetCheckInOut_futureCheckOut_data_bed_room_section;
}

export interface GetCheckInOut_futureCheckOut_data_bed {
  __typename: "HotelBed";
  id: string;
  room: GetCheckInOut_futureCheckOut_data_bed_room;
}

export interface GetCheckInOut_futureCheckOut_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface GetCheckInOut_futureCheckOut_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: GetCheckInOut_futureCheckOut_data_bed;
  employee: GetCheckInOut_futureCheckOut_data_employee;
}

export interface GetCheckInOut_futureCheckOut {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetCheckInOut_futureCheckOut_data[];
}

export interface GetCheckInOut_checkedIn_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetCheckInOut_checkedIn_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetCheckInOut_checkedIn_data_bed_room_section_hotel;
}

export interface GetCheckInOut_checkedIn_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetCheckInOut_checkedIn_data_bed_room_section;
}

export interface GetCheckInOut_checkedIn_data_bed {
  __typename: "HotelBed";
  id: string;
  room: GetCheckInOut_checkedIn_data_bed_room;
}

export interface GetCheckInOut_checkedIn_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface GetCheckInOut_checkedIn_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: GetCheckInOut_checkedIn_data_bed;
  employee: GetCheckInOut_checkedIn_data_employee;
}

export interface GetCheckInOut_checkedIn {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetCheckInOut_checkedIn_data[];
}

export interface GetCheckInOut_checkedOut_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetCheckInOut_checkedOut_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetCheckInOut_checkedOut_data_bed_room_section_hotel;
}

export interface GetCheckInOut_checkedOut_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetCheckInOut_checkedOut_data_bed_room_section;
}

export interface GetCheckInOut_checkedOut_data_bed {
  __typename: "HotelBed";
  id: string;
  room: GetCheckInOut_checkedOut_data_bed_room;
}

export interface GetCheckInOut_checkedOut_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface GetCheckInOut_checkedOut_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: GetCheckInOut_checkedOut_data_bed;
  employee: GetCheckInOut_checkedOut_data_employee;
}

export interface GetCheckInOut_checkedOut {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetCheckInOut_checkedOut_data[];
}

export interface GetCheckInOut {
  /**
   * Fetch all reservations adhering to provided criteria
   */
  checkIn: GetCheckInOut_checkIn | null;
  /**
   * Fetch all reservations adhering to provided criteria
   */
  checkOut: GetCheckInOut_checkOut | null;
  /**
   * Fetch all reservations adhering to provided criteria
   */
  futureCheckIn: GetCheckInOut_futureCheckIn | null;
  /**
   * Fetch all reservations adhering to provided criteria
   */
  futureCheckOut: GetCheckInOut_futureCheckOut | null;
  /**
   * Fetch all reservations adhering to provided criteria
   */
  checkedIn: GetCheckInOut_checkedIn | null;
  /**
   * Fetch all reservations adhering to provided criteria
   */
  checkedOut: GetCheckInOut_checkedOut | null;
}

export interface GetCheckInOutVariables {
  today: any;
  date: any;
  future: any;
  hotel?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHotels
// ====================================================

export interface GetHotels_hotels_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of total available items.
   */
  total: number;
  /**
   * Index of the last available page.
   */
  lastPage: number;
  /**
   * Index of the current page.
   */
  currentPage: number;
}

export interface GetHotels_hotels_data_sections_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetHotels_hotels_data_sections {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetHotels_hotels_data_sections_hotel;
}

export interface GetHotels_hotels_data {
  __typename: "Hotel";
  id: string;
  name: string;
  sections: GetHotels_hotels_data_sections[];
}

export interface GetHotels_hotels {
  __typename: "HotelPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: GetHotels_hotels_paginatorInfo;
  /**
   * A list of Hotel items.
   */
  data: GetHotels_hotels_data[];
}

export interface GetHotels {
  /**
   * Fetch all hotels
   */
  hotels: GetHotels_hotels | null;
}

export interface GetHotelsVariables {
  page: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHotel
// ====================================================

export interface CreateHotel_createHotel_sections_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface CreateHotel_createHotel_sections {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: CreateHotel_createHotel_sections_hotel;
}

export interface CreateHotel_createHotel {
  __typename: "Hotel";
  id: string;
  name: string;
  sections: CreateHotel_createHotel_sections[];
}

export interface CreateHotel {
  /**
   * Create a hotel
   */
  createHotel: CreateHotel_createHotel | null;
}

export interface CreateHotelVariables {
  input: HotelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHotelSection
// ====================================================

export interface CreateHotelSection_createHotelSection_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface CreateHotelSection_createHotelSection {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: CreateHotelSection_createHotelSection_hotel;
}

export interface CreateHotelSection {
  /**
   * Create a hotel section
   */
  createHotelSection: CreateHotelSection_createHotelSection | null;
}

export interface CreateHotelSectionVariables {
  input: HotelSectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHotel
// ====================================================

export interface DeleteHotel_deleteHotel {
  __typename: "Hotel";
  id: string;
}

export interface DeleteHotel {
  /**
   * Delete a hotel hotel
   */
  deleteHotel: DeleteHotel_deleteHotel | null;
}

export interface DeleteHotelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHotelSection
// ====================================================

export interface DeleteHotelSection_deleteHotelSection {
  __typename: "HotelSection";
  id: string;
}

export interface DeleteHotelSection {
  /**
   * Delete a hotel section
   */
  deleteHotelSection: DeleteHotelSection_deleteHotelSection | null;
}

export interface DeleteHotelSectionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlanboard
// ====================================================

export interface GetPlanboard_extended_data_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetPlanboard_extended_data_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetPlanboard_extended_data_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetPlanboard_extended_data_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetPlanboard_extended_data_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetPlanboard_extended_data_functions_code;
  description: string;
  startedAt: any;
}

export interface GetPlanboard_extended_data_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetPlanboard_extended_data_departments_department {
  __typename: "Department";
  id: string;
  area: GetPlanboard_extended_data_departments_department_area | null;
}

export interface GetPlanboard_extended_data_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetPlanboard_extended_data_departments_department;
}

export interface GetPlanboard_extended_data_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_extended_data_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_extended_data_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetPlanboard_extended_data_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetPlanboard_extended_data_remarks_createdUser;
}

export interface GetPlanboard_extended_data_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetPlanboard_extended_data_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetPlanboard_extended_data_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetPlanboard_extended_data_currentHotelReservation_bed_room_section_hotel;
}

export interface GetPlanboard_extended_data_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetPlanboard_extended_data_currentHotelReservation_bed_room_section;
}

export interface GetPlanboard_extended_data_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetPlanboard_extended_data_currentHotelReservation_bed_room;
}

export interface GetPlanboard_extended_data_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetPlanboard_extended_data_currentHotelReservation_bed;
}

export interface GetPlanboard_extended_data_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_extended_data_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetPlanboard_extended_data {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetPlanboard_extended_data_currentFunction | null;
  functions: GetPlanboard_extended_data_functions[];
  departments: GetPlanboard_extended_data_departments[];
  absences: GetPlanboard_extended_data_absences[];
  leaves: GetPlanboard_extended_data_leaves[];
  remarks: GetPlanboard_extended_data_remarks[];
  hotelReservations: GetPlanboard_extended_data_hotelReservations[];
  currentHotelReservation: GetPlanboard_extended_data_currentHotelReservation | null;
  employments: GetPlanboard_extended_data_employments[];
  tempAgency: GetPlanboard_extended_data_tempAgency | null;
}

export interface GetPlanboard_extended {
  __typename: "EmployeePaginator";
  /**
   * A list of Employee items.
   */
  data: GetPlanboard_extended_data[];
}

export interface GetPlanboard_ended_data_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetPlanboard_ended_data_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetPlanboard_ended_data_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetPlanboard_ended_data_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetPlanboard_ended_data_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetPlanboard_ended_data_functions_code;
  description: string;
  startedAt: any;
}

export interface GetPlanboard_ended_data_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetPlanboard_ended_data_departments_department {
  __typename: "Department";
  id: string;
  area: GetPlanboard_ended_data_departments_department_area | null;
}

export interface GetPlanboard_ended_data_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetPlanboard_ended_data_departments_department;
}

export interface GetPlanboard_ended_data_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_ended_data_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_ended_data_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetPlanboard_ended_data_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetPlanboard_ended_data_remarks_createdUser;
}

export interface GetPlanboard_ended_data_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetPlanboard_ended_data_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetPlanboard_ended_data_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetPlanboard_ended_data_currentHotelReservation_bed_room_section_hotel;
}

export interface GetPlanboard_ended_data_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetPlanboard_ended_data_currentHotelReservation_bed_room_section;
}

export interface GetPlanboard_ended_data_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetPlanboard_ended_data_currentHotelReservation_bed_room;
}

export interface GetPlanboard_ended_data_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetPlanboard_ended_data_currentHotelReservation_bed;
}

export interface GetPlanboard_ended_data_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_ended_data_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetPlanboard_ended_data {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetPlanboard_ended_data_currentFunction | null;
  functions: GetPlanboard_ended_data_functions[];
  departments: GetPlanboard_ended_data_departments[];
  absences: GetPlanboard_ended_data_absences[];
  leaves: GetPlanboard_ended_data_leaves[];
  remarks: GetPlanboard_ended_data_remarks[];
  hotelReservations: GetPlanboard_ended_data_hotelReservations[];
  currentHotelReservation: GetPlanboard_ended_data_currentHotelReservation | null;
  employments: GetPlanboard_ended_data_employments[];
  tempAgency: GetPlanboard_ended_data_tempAgency | null;
}

export interface GetPlanboard_ended {
  __typename: "EmployeePaginator";
  /**
   * A list of Employee items.
   */
  data: GetPlanboard_ended_data[];
}

export interface GetPlanboard_applicants_data_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetPlanboard_applicants_data_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetPlanboard_applicants_data_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetPlanboard_applicants_data_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetPlanboard_applicants_data_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetPlanboard_applicants_data_functions_code;
  description: string;
  startedAt: any;
}

export interface GetPlanboard_applicants_data_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetPlanboard_applicants_data_departments_department {
  __typename: "Department";
  id: string;
  area: GetPlanboard_applicants_data_departments_department_area | null;
}

export interface GetPlanboard_applicants_data_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetPlanboard_applicants_data_departments_department;
}

export interface GetPlanboard_applicants_data_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_applicants_data_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_applicants_data_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetPlanboard_applicants_data_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetPlanboard_applicants_data_remarks_createdUser;
}

export interface GetPlanboard_applicants_data_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetPlanboard_applicants_data_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetPlanboard_applicants_data_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetPlanboard_applicants_data_currentHotelReservation_bed_room_section_hotel;
}

export interface GetPlanboard_applicants_data_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetPlanboard_applicants_data_currentHotelReservation_bed_room_section;
}

export interface GetPlanboard_applicants_data_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetPlanboard_applicants_data_currentHotelReservation_bed_room;
}

export interface GetPlanboard_applicants_data_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetPlanboard_applicants_data_currentHotelReservation_bed;
}

export interface GetPlanboard_applicants_data_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_applicants_data_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetPlanboard_applicants_data {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetPlanboard_applicants_data_currentFunction | null;
  functions: GetPlanboard_applicants_data_functions[];
  departments: GetPlanboard_applicants_data_departments[];
  absences: GetPlanboard_applicants_data_absences[];
  leaves: GetPlanboard_applicants_data_leaves[];
  remarks: GetPlanboard_applicants_data_remarks[];
  hotelReservations: GetPlanboard_applicants_data_hotelReservations[];
  currentHotelReservation: GetPlanboard_applicants_data_currentHotelReservation | null;
  employments: GetPlanboard_applicants_data_employments[];
  tempAgency: GetPlanboard_applicants_data_tempAgency | null;
}

export interface GetPlanboard_applicants {
  __typename: "EmployeePaginator";
  /**
   * A list of Employee items.
   */
  data: GetPlanboard_applicants_data[];
}

export interface GetPlanboard_employees_data_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetPlanboard_employees_data_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetPlanboard_employees_data_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetPlanboard_employees_data_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetPlanboard_employees_data_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetPlanboard_employees_data_functions_code;
  description: string;
  startedAt: any;
}

export interface GetPlanboard_employees_data_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetPlanboard_employees_data_departments_department {
  __typename: "Department";
  id: string;
  area: GetPlanboard_employees_data_departments_department_area | null;
}

export interface GetPlanboard_employees_data_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetPlanboard_employees_data_departments_department;
}

export interface GetPlanboard_employees_data_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_employees_data_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_employees_data_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetPlanboard_employees_data_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetPlanboard_employees_data_remarks_createdUser;
}

export interface GetPlanboard_employees_data_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetPlanboard_employees_data_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetPlanboard_employees_data_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetPlanboard_employees_data_currentHotelReservation_bed_room_section_hotel;
}

export interface GetPlanboard_employees_data_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetPlanboard_employees_data_currentHotelReservation_bed_room_section;
}

export interface GetPlanboard_employees_data_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetPlanboard_employees_data_currentHotelReservation_bed_room;
}

export interface GetPlanboard_employees_data_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetPlanboard_employees_data_currentHotelReservation_bed;
}

export interface GetPlanboard_employees_data_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetPlanboard_employees_data_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetPlanboard_employees_data {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetPlanboard_employees_data_currentFunction | null;
  functions: GetPlanboard_employees_data_functions[];
  departments: GetPlanboard_employees_data_departments[];
  absences: GetPlanboard_employees_data_absences[];
  leaves: GetPlanboard_employees_data_leaves[];
  remarks: GetPlanboard_employees_data_remarks[];
  hotelReservations: GetPlanboard_employees_data_hotelReservations[];
  currentHotelReservation: GetPlanboard_employees_data_currentHotelReservation | null;
  employments: GetPlanboard_employees_data_employments[];
  tempAgency: GetPlanboard_employees_data_tempAgency | null;
}

export interface GetPlanboard_employees {
  __typename: "EmployeePaginator";
  /**
   * A list of Employee items.
   */
  data: GetPlanboard_employees_data[];
}

export interface GetPlanboard {
  /**
   *  Paginate employees 
   */
  extended: GetPlanboard_extended | null;
  /**
   *  Paginate employees 
   */
  ended: GetPlanboard_ended | null;
  /**
   *  Paginate employees 
   */
  applicants: GetPlanboard_applicants | null;
  /**
   *  Paginate employees 
   */
  employees: GetPlanboard_employees | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHotelReservation
// ====================================================

export interface DeleteHotelReservation_deleteHotelReservation {
  __typename: "HotelReservation";
  id: string;
}

export interface DeleteHotelReservation {
  /**
   * Delete a hotel reservation
   */
  deleteHotelReservation: DeleteHotelReservation_deleteHotelReservation | null;
}

export interface DeleteHotelReservationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterReservationDates
// ====================================================

export interface AlterReservationDates_alterHotelReservation {
  __typename: "HotelReservation";
  id: string;
  state: HotelReservationState;
  startAt: any;
  endAt: any | null;
}

export interface AlterReservationDates {
  /**
   * Alter the dates of a hotel reservation
   */
  alterHotelReservation: AlterReservationDates_alterHotelReservation | null;
}

export interface AlterReservationDatesVariables {
  input: AlterHotelReservationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterReservationState
// ====================================================

export interface AlterReservationState_alterHotelReservationState {
  __typename: "HotelReservation";
  id: string;
  state: HotelReservationState;
  inProgressAt: any | null;
  completedAt: any | null;
}

export interface AlterReservationState {
  /**
   * Alter the state of a hotel reservation
   */
  alterHotelReservationState: AlterReservationState_alterHotelReservationState | null;
}

export interface AlterReservationStateVariables {
  id: string;
  state: HotelReservationState;
  inProgressAt?: any | null;
  completedAt?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAvailableRooms
// ====================================================

export interface FindAvailableRooms_hotelFindAvailableRooms_data_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface FindAvailableRooms_hotelFindAvailableRooms_data_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: FindAvailableRooms_hotelFindAvailableRooms_data_section_hotel;
}

export interface FindAvailableRooms_hotelFindAvailableRooms_data {
  __typename: "HotelRoomAvailability";
  id: string;
  name: string;
  section: FindAvailableRooms_hotelFindAvailableRooms_data_section;
  bedsAvailable: string[];
  bedsUnavailable: string[];
}

export interface FindAvailableRooms_hotelFindAvailableRooms {
  __typename: "HotelRoomAvailabilityPaginator";
  /**
   * A list of HotelRoomAvailability items.
   */
  data: FindAvailableRooms_hotelFindAvailableRooms_data[];
}

export interface FindAvailableRooms {
  /**
   * Fetch a available rooms for certain filters
   */
  hotelFindAvailableRooms: FindAvailableRooms_hotelFindAvailableRooms | null;
}

export interface FindAvailableRoomsVariables {
  startAt: any;
  endAt?: any | null;
  employees: string[];
  hotel?: string | null;
  section?: string | null;
  room?: string | null;
  gender?: Gender | null;
  ageMin?: number | null;
  ageMax?: number | null;
  allowTemps?: boolean | null;
  nationalities?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReservation
// ====================================================

export interface CreateReservation_createHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
}

export interface CreateReservation_createHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  hotel: CreateReservation_createHotelReservation_bed_room_section_hotel;
}

export interface CreateReservation_createHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  section: CreateReservation_createHotelReservation_bed_room_section;
}

export interface CreateReservation_createHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: CreateReservation_createHotelReservation_bed_room;
}

export interface CreateReservation_createHotelReservation {
  __typename: "HotelReservation";
  id: string;
  bed: CreateReservation_createHotelReservation_bed;
}

export interface CreateReservation {
  /**
   * Create a single reservation
   */
  createHotelReservation: CreateReservation_createHotelReservation;
}

export interface CreateReservationVariables {
  input: HotelReservationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNationalities
// ====================================================

export interface GetNationalities {
  nationalities: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEmployeeSuggestions
// ====================================================

export interface GetEmployeeSuggestions_employees_data_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetEmployeeSuggestions_employees_data_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetEmployeeSuggestions_employees_data_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetEmployeeSuggestions_employees_data_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetEmployeeSuggestions_employees_data_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetEmployeeSuggestions_employees_data_functions_code;
  description: string;
  startedAt: any;
}

export interface GetEmployeeSuggestions_employees_data_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetEmployeeSuggestions_employees_data_departments_department {
  __typename: "Department";
  id: string;
  area: GetEmployeeSuggestions_employees_data_departments_department_area | null;
}

export interface GetEmployeeSuggestions_employees_data_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetEmployeeSuggestions_employees_data_departments_department;
}

export interface GetEmployeeSuggestions_employees_data_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetEmployeeSuggestions_employees_data_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetEmployeeSuggestions_employees_data_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetEmployeeSuggestions_employees_data_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetEmployeeSuggestions_employees_data_remarks_createdUser;
}

export interface GetEmployeeSuggestions_employees_data_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetEmployeeSuggestions_employees_data_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetEmployeeSuggestions_employees_data_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetEmployeeSuggestions_employees_data_currentHotelReservation_bed_room_section_hotel;
}

export interface GetEmployeeSuggestions_employees_data_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetEmployeeSuggestions_employees_data_currentHotelReservation_bed_room_section;
}

export interface GetEmployeeSuggestions_employees_data_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetEmployeeSuggestions_employees_data_currentHotelReservation_bed_room;
}

export interface GetEmployeeSuggestions_employees_data_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetEmployeeSuggestions_employees_data_currentHotelReservation_bed;
}

export interface GetEmployeeSuggestions_employees_data_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetEmployeeSuggestions_employees_data_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetEmployeeSuggestions_employees_data {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetEmployeeSuggestions_employees_data_currentFunction | null;
  functions: GetEmployeeSuggestions_employees_data_functions[];
  departments: GetEmployeeSuggestions_employees_data_departments[];
  absences: GetEmployeeSuggestions_employees_data_absences[];
  leaves: GetEmployeeSuggestions_employees_data_leaves[];
  remarks: GetEmployeeSuggestions_employees_data_remarks[];
  hotelReservations: GetEmployeeSuggestions_employees_data_hotelReservations[];
  currentHotelReservation: GetEmployeeSuggestions_employees_data_currentHotelReservation | null;
  employments: GetEmployeeSuggestions_employees_data_employments[];
  tempAgency: GetEmployeeSuggestions_employees_data_tempAgency | null;
}

export interface GetEmployeeSuggestions_employees {
  __typename: "EmployeePaginator";
  /**
   * A list of Employee items.
   */
  data: GetEmployeeSuggestions_employees_data[];
}

export interface GetEmployeeSuggestions {
  /**
   *  Paginate employees 
   */
  employees: GetEmployeeSuggestions_employees | null;
}

export interface GetEmployeeSuggestionsVariables {
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHotelWeeks
// ====================================================

export interface GetHotelWeeks_hotelWeeks_buckets {
  __typename: "HotelWeekBucket";
  id: string;
  name: string;
  bedsOccupied: number;
  bedsTotal: number;
}

export interface GetHotelWeeks_hotelWeeks {
  __typename: "HotelWeek";
  id: string;
  startAt: any;
  endAt: any;
  buckets: GetHotelWeeks_hotelWeeks_buckets[];
}

export interface GetHotelWeeks {
  /**
   * Fetch a week overview of hotel occupancy
   */
  hotelWeeks: GetHotelWeeks_hotelWeeks[];
}

export interface GetHotelWeeksVariables {
  year: number;
  hotel?: string | null;
  hotelSection?: string | null;
  week?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHotelRoomReservations
// ====================================================

export interface GetHotelRoomReservations_history_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetHotelRoomReservations_history_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetHotelRoomReservations_history_data_bed_room_section_hotel;
}

export interface GetHotelRoomReservations_history_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetHotelRoomReservations_history_data_bed_room_section;
}

export interface GetHotelRoomReservations_history_data_bed {
  __typename: "HotelBed";
  id: string;
  room: GetHotelRoomReservations_history_data_bed_room;
}

export interface GetHotelRoomReservations_history_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface GetHotelRoomReservations_history_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: GetHotelRoomReservations_history_data_bed;
  employee: GetHotelRoomReservations_history_data_employee;
}

export interface GetHotelRoomReservations_history {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetHotelRoomReservations_history_data[];
}

export interface GetHotelRoomReservations_future_data_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetHotelRoomReservations_future_data_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetHotelRoomReservations_future_data_bed_room_section_hotel;
}

export interface GetHotelRoomReservations_future_data_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetHotelRoomReservations_future_data_bed_room_section;
}

export interface GetHotelRoomReservations_future_data_bed {
  __typename: "HotelBed";
  id: string;
  room: GetHotelRoomReservations_future_data_bed_room;
}

export interface GetHotelRoomReservations_future_data_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface GetHotelRoomReservations_future_data {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: GetHotelRoomReservations_future_data_bed;
  employee: GetHotelRoomReservations_future_data_employee;
}

export interface GetHotelRoomReservations_future {
  __typename: "HotelReservationPaginator";
  /**
   * A list of HotelReservation items.
   */
  data: GetHotelRoomReservations_future_data[];
}

export interface GetHotelRoomReservations {
  /**
   * Fetch all reservations for a room, adhering to provided criteria
   */
  history: GetHotelRoomReservations_history | null;
  /**
   * Fetch all reservations for a room, adhering to provided criteria
   */
  future: GetHotelRoomReservations_future | null;
}

export interface GetHotelRoomReservationsVariables {
  today: any;
  room: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHotelRoom
// ====================================================

export interface DeleteHotelRoom_deleteHotelRoom {
  __typename: "HotelRoom";
  id: string;
}

export interface DeleteHotelRoom {
  /**
   * Delete a hotel room
   */
  deleteHotelRoom: DeleteHotelRoom_deleteHotelRoom | null;
}

export interface DeleteHotelRoomVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHotelRoomCategories
// ====================================================

export interface GetHotelRoomCategories_hotelRoomCategories_data_rate {
  __typename: "HotelRoomRates";
  id: string;
  amount: number;
}

export interface GetHotelRoomCategories_hotelRoomCategories_data {
  __typename: "HotelRoomCategory";
  id: string;
  name: string;
  rate: GetHotelRoomCategories_hotelRoomCategories_data_rate;
}

export interface GetHotelRoomCategories_hotelRoomCategories {
  __typename: "HotelRoomCategoryPaginator";
  /**
   * A list of HotelRoomCategory items.
   */
  data: GetHotelRoomCategories_hotelRoomCategories_data[];
}

export interface GetHotelRoomCategories {
  /**
   * Fetch all hotel room categories
   */
  hotelRoomCategories: GetHotelRoomCategories_hotelRoomCategories | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHotelRoomRemark
// ====================================================

export interface DeleteHotelRoomRemark_deleteHotelRoomRemark {
  __typename: "HotelRoomRemark";
  id: string;
}

export interface DeleteHotelRoomRemark {
  /**
   * Delete a hotel room remark
   */
  deleteHotelRoomRemark: DeleteHotelRoomRemark_deleteHotelRoomRemark | null;
}

export interface DeleteHotelRoomRemarkVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHotelRoomRemark
// ====================================================

export interface CreateHotelRoomRemark_createHotelRoomRemark_createdBy {
  __typename: "User";
  id: string;
  name: string;
}

export interface CreateHotelRoomRemark_createHotelRoomRemark_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
}

export interface CreateHotelRoomRemark_createHotelRoomRemark_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: CreateHotelRoomRemark_createHotelRoomRemark_room_section;
}

export interface CreateHotelRoomRemark_createHotelRoomRemark {
  __typename: "HotelRoomRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdBy: CreateHotelRoomRemark_createHotelRoomRemark_createdBy;
  room: CreateHotelRoomRemark_createHotelRoomRemark_room;
}

export interface CreateHotelRoomRemark {
  /**
   * Create a hotel room remark
   */
  createHotelRoomRemark: CreateHotelRoomRemark_createHotelRoomRemark | null;
}

export interface CreateHotelRoomRemarkVariables {
  input: HotelRoomRemarkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterHotelRoomRemark
// ====================================================

export interface AlterHotelRoomRemark_alterHotelRoomRemark_createdBy {
  __typename: "User";
  id: string;
  name: string;
}

export interface AlterHotelRoomRemark_alterHotelRoomRemark_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
}

export interface AlterHotelRoomRemark_alterHotelRoomRemark_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: AlterHotelRoomRemark_alterHotelRoomRemark_room_section;
}

export interface AlterHotelRoomRemark_alterHotelRoomRemark {
  __typename: "HotelRoomRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdBy: AlterHotelRoomRemark_alterHotelRoomRemark_createdBy;
  room: AlterHotelRoomRemark_alterHotelRoomRemark_room;
}

export interface AlterHotelRoomRemark {
  /**
   * Alter a hotel room remark
   */
  alterHotelRoomRemark: AlterHotelRoomRemark_alterHotelRoomRemark | null;
}

export interface AlterHotelRoomRemarkVariables {
  id: string;
  remark: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterHotelRoom
// ====================================================

export interface AlterHotelRoom_alterHotelRoom_remarks_createdBy {
  __typename: "User";
  id: string;
  name: string;
}

export interface AlterHotelRoom_alterHotelRoom_remarks_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
}

export interface AlterHotelRoom_alterHotelRoom_remarks_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: AlterHotelRoom_alterHotelRoom_remarks_room_section;
}

export interface AlterHotelRoom_alterHotelRoom_remarks {
  __typename: "HotelRoomRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdBy: AlterHotelRoom_alterHotelRoom_remarks_createdBy;
  room: AlterHotelRoom_alterHotelRoom_remarks_room;
}

export interface AlterHotelRoom_alterHotelRoom {
  __typename: "HotelRoom";
  id: string;
  name: string;
  remarks: AlterHotelRoom_alterHotelRoom_remarks[];
}

export interface AlterHotelRoom {
  /**
   * Alter the name of a hotel room
   */
  alterHotelRoom: AlterHotelRoom_alterHotelRoom | null;
}

export interface AlterHotelRoomVariables {
  id: string;
  name: string;
  category: HotelRoomCategoryBelongsTo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterHotel
// ====================================================

export interface AlterHotel_alterHotel_sections_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface AlterHotel_alterHotel_sections {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: AlterHotel_alterHotel_sections_hotel;
}

export interface AlterHotel_alterHotel {
  __typename: "Hotel";
  id: string;
  name: string;
  sections: AlterHotel_alterHotel_sections[];
}

export interface AlterHotel {
  /**
   * Alter the name of a hotel
   */
  alterHotel: AlterHotel_alterHotel | null;
}

export interface AlterHotelVariables {
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterHotelSection
// ====================================================

export interface AlterHotelSection_alterHotelSection_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface AlterHotelSection_alterHotelSection {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: AlterHotelSection_alterHotelSection_hotel;
}

export interface AlterHotelSection {
  /**
   * Alter the name of a hotel section
   */
  alterHotelSection: AlterHotelSection_alterHotelSection | null;
}

export interface AlterHotelSectionVariables {
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterDefaultArea
// ====================================================

export interface AlterDefaultArea_alterDefaultArea {
  __typename: "Area";
  id: string;
}

export interface AlterDefaultArea {
  /**
   *  CHange the default area that is shown upon load of the tool 
   */
  alterDefaultArea: AlterDefaultArea_alterDefaultArea | null;
}

export interface AlterDefaultAreaVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetYearWeeksByAreaId
// ====================================================

export interface GetYearWeeksByAreaId_yearWeeksByAreaId_finalForecast {
  __typename: "WeekForecast";
  totalHours: number;
}

export interface GetYearWeeksByAreaId_yearWeeksByAreaId_twoWeekForecast {
  __typename: "WeekForecast";
  totalHours: number;
}

export interface GetYearWeeksByAreaId_yearWeeksByAreaId_threeWeekForecast {
  __typename: "WeekForecast";
  totalHours: number;
}

export interface GetYearWeeksByAreaId_yearWeeksByAreaId_fourWeekForecast {
  __typename: "WeekForecast";
  totalHours: number;
}

export interface GetYearWeeksByAreaId_yearWeeksByAreaId {
  __typename: "Week";
  id: string;
  year: number;
  number: number;
  hoursDemand: number;
  hoursDemandCosts: number;
  hoursSupply: number;
  hoursFTE: number;
  budget: number;
  hoursRealized: number;
  startAt: any;
  endAt: any;
  transferredInCount: number;
  tempEmployeesCount: number;
  finalForecast: GetYearWeeksByAreaId_yearWeeksByAreaId_finalForecast | null;
  twoWeekForecast: GetYearWeeksByAreaId_yearWeeksByAreaId_twoWeekForecast | null;
  threeWeekForecast: GetYearWeeksByAreaId_yearWeeksByAreaId_threeWeekForecast | null;
  fourWeekForecast: GetYearWeeksByAreaId_yearWeeksByAreaId_fourWeekForecast | null;
}

export interface GetYearWeeksByAreaId {
  /**
   *  Get all weeks by area ID and year 
   */
  yearWeeksByAreaId: GetYearWeeksByAreaId_yearWeeksByAreaId[];
}

export interface GetYearWeeksByAreaIdVariables {
  areaId: string;
  year: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWeekschemaById
// ====================================================

export interface GetWeekschemaById_weekById_lockedUser {
  __typename: "User";
  name: string;
}

export interface GetWeekschemaById_weekById_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetWeekschemaById_weekById_forecasts {
  __typename: "WeekForecast";
  state: WeekForecastState;
}

export interface GetWeekschemaById_weekById_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_remarks {
  __typename: "WeekRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekschemaById_weekById_remarks_createdUser;
}

export interface GetWeekschemaById_weekById_employments_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_employments_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_employments_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_employments_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_employments_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_employments_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_employments_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetWeekschemaById_weekById_employments_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetWeekschemaById_weekById_employments_employee_departments_department_area | null;
}

export interface GetWeekschemaById_weekById_employments_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetWeekschemaById_weekById_employments_employee_departments_department;
}

export interface GetWeekschemaById_weekById_employments_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_employments_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_employments_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_employments_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekschemaById_weekById_employments_employee_remarks_createdUser;
}

export interface GetWeekschemaById_weekById_employments_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetWeekschemaById_weekById_employments_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_employments_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetWeekschemaById_weekById_employments_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetWeekschemaById_weekById_employments_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetWeekschemaById_weekById_employments_employee_currentHotelReservation_bed_room_section;
}

export interface GetWeekschemaById_weekById_employments_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetWeekschemaById_weekById_employments_employee_currentHotelReservation_bed_room;
}

export interface GetWeekschemaById_weekById_employments_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetWeekschemaById_weekById_employments_employee_currentHotelReservation_bed;
}

export interface GetWeekschemaById_weekById_employments_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_employments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetWeekschemaById_weekById_employments_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetWeekschemaById_weekById_employments_employee_currentFunction | null;
  functions: GetWeekschemaById_weekById_employments_employee_functions[];
  departments: GetWeekschemaById_weekById_employments_employee_departments[];
  absences: GetWeekschemaById_weekById_employments_employee_absences[];
  leaves: GetWeekschemaById_weekById_employments_employee_leaves[];
  remarks: GetWeekschemaById_weekById_employments_employee_remarks[];
  hotelReservations: GetWeekschemaById_weekById_employments_employee_hotelReservations[];
  currentHotelReservation: GetWeekschemaById_weekById_employments_employee_currentHotelReservation | null;
  employments: GetWeekschemaById_weekById_employments_employee_employments[];
  tempAgency: GetWeekschemaById_weekById_employments_employee_tempAgency | null;
}

export interface GetWeekschemaById_weekById_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetWeekschemaById_weekById_employments_employee;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_tempEmployments_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_tempEmployments_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetWeekschemaById_weekById_tempEmployments_employee_departments_department_area | null;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetWeekschemaById_weekById_tempEmployments_employee_departments_department;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekschemaById_weekById_tempEmployments_employee_remarks_createdUser;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation_bed_room_section;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation_bed_room;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation_bed;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetWeekschemaById_weekById_tempEmployments_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetWeekschemaById_weekById_tempEmployments_employee_currentFunction | null;
  functions: GetWeekschemaById_weekById_tempEmployments_employee_functions[];
  departments: GetWeekschemaById_weekById_tempEmployments_employee_departments[];
  absences: GetWeekschemaById_weekById_tempEmployments_employee_absences[];
  leaves: GetWeekschemaById_weekById_tempEmployments_employee_leaves[];
  remarks: GetWeekschemaById_weekById_tempEmployments_employee_remarks[];
  hotelReservations: GetWeekschemaById_weekById_tempEmployments_employee_hotelReservations[];
  currentHotelReservation: GetWeekschemaById_weekById_tempEmployments_employee_currentHotelReservation | null;
  employments: GetWeekschemaById_weekById_tempEmployments_employee_employments[];
  tempAgency: GetWeekschemaById_weekById_tempEmployments_employee_tempAgency | null;
}

export interface GetWeekschemaById_weekById_tempEmployments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetWeekschemaById_weekById_tempEmployments_employee;
}

export interface GetWeekschemaById_weekById_absences_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_absences_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_absences_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_absences_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_absences_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_absences_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_absences_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetWeekschemaById_weekById_absences_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetWeekschemaById_weekById_absences_employee_departments_department_area | null;
}

export interface GetWeekschemaById_weekById_absences_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetWeekschemaById_weekById_absences_employee_departments_department;
}

export interface GetWeekschemaById_weekById_absences_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_absences_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_absences_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_absences_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekschemaById_weekById_absences_employee_remarks_createdUser;
}

export interface GetWeekschemaById_weekById_absences_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetWeekschemaById_weekById_absences_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_absences_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetWeekschemaById_weekById_absences_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetWeekschemaById_weekById_absences_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetWeekschemaById_weekById_absences_employee_currentHotelReservation_bed_room_section;
}

export interface GetWeekschemaById_weekById_absences_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetWeekschemaById_weekById_absences_employee_currentHotelReservation_bed_room;
}

export interface GetWeekschemaById_weekById_absences_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetWeekschemaById_weekById_absences_employee_currentHotelReservation_bed;
}

export interface GetWeekschemaById_weekById_absences_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_absences_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetWeekschemaById_weekById_absences_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetWeekschemaById_weekById_absences_employee_currentFunction | null;
  functions: GetWeekschemaById_weekById_absences_employee_functions[];
  departments: GetWeekschemaById_weekById_absences_employee_departments[];
  absences: GetWeekschemaById_weekById_absences_employee_absences[];
  leaves: GetWeekschemaById_weekById_absences_employee_leaves[];
  remarks: GetWeekschemaById_weekById_absences_employee_remarks[];
  hotelReservations: GetWeekschemaById_weekById_absences_employee_hotelReservations[];
  currentHotelReservation: GetWeekschemaById_weekById_absences_employee_currentHotelReservation | null;
  employments: GetWeekschemaById_weekById_absences_employee_employments[];
  tempAgency: GetWeekschemaById_weekById_absences_employee_tempAgency | null;
}

export interface GetWeekschemaById_weekById_absences {
  __typename: "EmployeeAbsence";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetWeekschemaById_weekById_absences_employee;
}

export interface GetWeekschemaById_weekById_leaves_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_leaves_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_leaves_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_leaves_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_leaves_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_leaves_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_leaves_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetWeekschemaById_weekById_leaves_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetWeekschemaById_weekById_leaves_employee_departments_department_area | null;
}

export interface GetWeekschemaById_weekById_leaves_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetWeekschemaById_weekById_leaves_employee_departments_department;
}

export interface GetWeekschemaById_weekById_leaves_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_leaves_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_leaves_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_leaves_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekschemaById_weekById_leaves_employee_remarks_createdUser;
}

export interface GetWeekschemaById_weekById_leaves_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetWeekschemaById_weekById_leaves_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_leaves_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetWeekschemaById_weekById_leaves_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetWeekschemaById_weekById_leaves_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetWeekschemaById_weekById_leaves_employee_currentHotelReservation_bed_room_section;
}

export interface GetWeekschemaById_weekById_leaves_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetWeekschemaById_weekById_leaves_employee_currentHotelReservation_bed_room;
}

export interface GetWeekschemaById_weekById_leaves_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetWeekschemaById_weekById_leaves_employee_currentHotelReservation_bed;
}

export interface GetWeekschemaById_weekById_leaves_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_leaves_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetWeekschemaById_weekById_leaves_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetWeekschemaById_weekById_leaves_employee_currentFunction | null;
  functions: GetWeekschemaById_weekById_leaves_employee_functions[];
  departments: GetWeekschemaById_weekById_leaves_employee_departments[];
  absences: GetWeekschemaById_weekById_leaves_employee_absences[];
  leaves: GetWeekschemaById_weekById_leaves_employee_leaves[];
  remarks: GetWeekschemaById_weekById_leaves_employee_remarks[];
  hotelReservations: GetWeekschemaById_weekById_leaves_employee_hotelReservations[];
  currentHotelReservation: GetWeekschemaById_weekById_leaves_employee_currentHotelReservation | null;
  employments: GetWeekschemaById_weekById_leaves_employee_employments[];
  tempAgency: GetWeekschemaById_weekById_leaves_employee_tempAgency | null;
}

export interface GetWeekschemaById_weekById_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetWeekschemaById_weekById_leaves_employee;
}

export interface GetWeekschemaById_weekById_transferredIn_weekFrom_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetWeekschemaById_weekById_transferredIn_weekFrom {
  __typename: "Week";
  id: string;
  isLocked: boolean;
  area: GetWeekschemaById_weekById_transferredIn_weekFrom_area;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_transferredIn_employment_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_transferredIn_employment_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetWeekschemaById_weekById_transferredIn_employment_employee_departments_department_area | null;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetWeekschemaById_weekById_transferredIn_employment_employee_departments_department;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekschemaById_weekById_transferredIn_employment_employee_remarks_createdUser;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation_bed_room_section;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation_bed_room;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation_bed;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetWeekschemaById_weekById_transferredIn_employment_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetWeekschemaById_weekById_transferredIn_employment_employee_currentFunction | null;
  functions: GetWeekschemaById_weekById_transferredIn_employment_employee_functions[];
  departments: GetWeekschemaById_weekById_transferredIn_employment_employee_departments[];
  absences: GetWeekschemaById_weekById_transferredIn_employment_employee_absences[];
  leaves: GetWeekschemaById_weekById_transferredIn_employment_employee_leaves[];
  remarks: GetWeekschemaById_weekById_transferredIn_employment_employee_remarks[];
  hotelReservations: GetWeekschemaById_weekById_transferredIn_employment_employee_hotelReservations[];
  currentHotelReservation: GetWeekschemaById_weekById_transferredIn_employment_employee_currentHotelReservation | null;
  employments: GetWeekschemaById_weekById_transferredIn_employment_employee_employments[];
  tempAgency: GetWeekschemaById_weekById_transferredIn_employment_employee_tempAgency | null;
}

export interface GetWeekschemaById_weekById_transferredIn_employment {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetWeekschemaById_weekById_transferredIn_employment_employee;
}

export interface GetWeekschemaById_weekById_transferredIn {
  __typename: "WeekException";
  id: string;
  weekFrom: GetWeekschemaById_weekById_transferredIn_weekFrom;
  employment: GetWeekschemaById_weekById_transferredIn_employment;
}

export interface GetWeekschemaById_weekById_transferredOut_weekTo_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetWeekschemaById_weekById_transferredOut_weekTo {
  __typename: "Week";
  id: string;
  isLocked: boolean;
  area: GetWeekschemaById_weekById_transferredOut_weekTo_area;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_transferredOut_employment_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekschemaById_weekById_transferredOut_employment_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetWeekschemaById_weekById_transferredOut_employment_employee_departments_department_area | null;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetWeekschemaById_weekById_transferredOut_employment_employee_departments_department;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekschemaById_weekById_transferredOut_employment_employee_remarks_createdUser;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation_bed_room_section;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation_bed_room;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation_bed;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetWeekschemaById_weekById_transferredOut_employment_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetWeekschemaById_weekById_transferredOut_employment_employee_currentFunction | null;
  functions: GetWeekschemaById_weekById_transferredOut_employment_employee_functions[];
  departments: GetWeekschemaById_weekById_transferredOut_employment_employee_departments[];
  absences: GetWeekschemaById_weekById_transferredOut_employment_employee_absences[];
  leaves: GetWeekschemaById_weekById_transferredOut_employment_employee_leaves[];
  remarks: GetWeekschemaById_weekById_transferredOut_employment_employee_remarks[];
  hotelReservations: GetWeekschemaById_weekById_transferredOut_employment_employee_hotelReservations[];
  currentHotelReservation: GetWeekschemaById_weekById_transferredOut_employment_employee_currentHotelReservation | null;
  employments: GetWeekschemaById_weekById_transferredOut_employment_employee_employments[];
  tempAgency: GetWeekschemaById_weekById_transferredOut_employment_employee_tempAgency | null;
}

export interface GetWeekschemaById_weekById_transferredOut_employment {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetWeekschemaById_weekById_transferredOut_employment_employee;
}

export interface GetWeekschemaById_weekById_transferredOut {
  __typename: "WeekException";
  id: string;
  weekTo: GetWeekschemaById_weekById_transferredOut_weekTo;
  employment: GetWeekschemaById_weekById_transferredOut_employment;
}

export interface GetWeekschemaById_weekById {
  __typename: "Week";
  id: string;
  year: number;
  number: number;
  hoursSupply: number;
  hoursDemand: number;
  hoursDemandCosts: number;
  hoursFTE: number;
  forecastRate: number;
  budget: number;
  startAt: any;
  endAt: any;
  isLocked: boolean;
  lockedAt: any | null;
  hasActivities: boolean;
  hasOpenForecastingTasks: boolean;
  lockedUser: GetWeekschemaById_weekById_lockedUser | null;
  area: GetWeekschemaById_weekById_area;
  forecasts: GetWeekschemaById_weekById_forecasts[];
  remarks: GetWeekschemaById_weekById_remarks[];
  employments: GetWeekschemaById_weekById_employments[];
  tempEmployments: GetWeekschemaById_weekById_tempEmployments[];
  absences: GetWeekschemaById_weekById_absences[];
  leaves: GetWeekschemaById_weekById_leaves[];
  transferredIn: GetWeekschemaById_weekById_transferredIn[];
  transferredOut: GetWeekschemaById_weekById_transferredOut[];
}

export interface GetWeekschemaById {
  /**
   *  Get a specific week 
   */
  weekById: GetWeekschemaById_weekById | null;
}

export interface GetWeekschemaByIdVariables {
  weekId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWeeksByAreaId
// ====================================================

export interface GetWeeksByAreaId_allWeeksByAreaId {
  __typename: "Week";
  id: string;
  year: number;
  number: number;
}

export interface GetWeeksByAreaId {
  /**
   *  Get all weeks by area ID 
   */
  allWeeksByAreaId: GetWeeksByAreaId_allWeeksByAreaId[];
}

export interface GetWeeksByAreaIdVariables {
  areaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptWeekTransferRequest
// ====================================================

export interface AcceptWeekTransferRequest_acceptWeekTransferRequest {
  __typename: "WeekTransferRequest";
  id: string;
  status: WeekTransferRequestStatus;
}

export interface AcceptWeekTransferRequest {
  /**
   *  Accept a transfer request, attaching the employees to be transferred 
   */
  acceptWeekTransferRequest: AcceptWeekTransferRequest_acceptWeekTransferRequest | null;
}

export interface AcceptWeekTransferRequestVariables {
  id: string;
  employees: string[];
  remarks?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWeekTransferRequest
// ====================================================

export interface CreateWeekTransferRequest_createWeekTransferRequest {
  __typename: "WeekTransferRequest";
  id: string;
  status: WeekTransferRequestStatus;
}

export interface CreateWeekTransferRequest {
  /**
   *  Create a transfer request 
   */
  createWeekTransferRequest: CreateWeekTransferRequest_createWeekTransferRequest | null;
}

export interface CreateWeekTransferRequestVariables {
  areaId: string;
  weekId: string;
  amountOfHours: number;
  directTransfer: boolean;
  remarks: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeclineWeekTransferRequest
// ====================================================

export interface DeclineWeekTransferRequest_declineWeekTransferRequest {
  __typename: "WeekTransferRequest";
  id: string;
}

export interface DeclineWeekTransferRequest {
  /**
   *  Decline a transfer request 
   */
  declineWeekTransferRequest: DeclineWeekTransferRequest_declineWeekTransferRequest | null;
}

export interface DeclineWeekTransferRequestVariables {
  id: string;
  remarks: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWeekTransferRequest
// ====================================================

export interface GetWeekTransferRequest_weekTransferRequest_user {
  __typename: "User";
  id: string;
  name: string;
  email: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_departments_department {
  __typename: "Department";
  id: string;
  area: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_departments_department_area | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_departments_department;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_remarks_createdUser;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation_bed_room_section;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation_bed_room;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation_bed;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentFunction | null;
  functions: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_functions[];
  departments: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_departments[];
  absences: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_absences[];
  leaves: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_leaves[];
  remarks: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_remarks[];
  hotelReservations: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_hotelReservations[];
  currentHotelReservation: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_currentHotelReservation | null;
  employments: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_employments[];
  tempAgency: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee_tempAgency | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments_employee;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekFrom {
  __typename: "Week";
  id: string;
  number: number;
  year: number;
  area: GetWeekTransferRequest_weekTransferRequest_weekFrom_area;
  employments: GetWeekTransferRequest_weekTransferRequest_weekFrom_employments[];
}

export interface GetWeekTransferRequest_weekTransferRequest_weekTo_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_weekTo {
  __typename: "Week";
  id: string;
  number: number;
  year: number;
  area: GetWeekTransferRequest_weekTransferRequest_weekTo_area;
}

export interface GetWeekTransferRequest_weekTransferRequest_logs_user {
  __typename: "User";
  name: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_logs {
  __typename: "WeekTransferRequestLog";
  id: string;
  fromStatus: WeekTransferRequestStatus | null;
  toStatus: WeekTransferRequestStatus;
  remarks: string | null;
  createdAt: any;
  user: GetWeekTransferRequest_weekTransferRequest_logs_user;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekTransferRequest_weekTransferRequest_employees_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: GetWeekTransferRequest_weekTransferRequest_employees_functions_code;
  description: string;
  startedAt: any;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_departments_department {
  __typename: "Department";
  id: string;
  area: GetWeekTransferRequest_weekTransferRequest_employees_departments_department_area | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: GetWeekTransferRequest_weekTransferRequest_employees_departments_department;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeekTransferRequest_weekTransferRequest_employees_remarks_createdUser;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation_bed_room_section_hotel;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation_bed_room_section;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation_bed_room;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation_bed;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetWeekTransferRequest_weekTransferRequest_employees {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: GetWeekTransferRequest_weekTransferRequest_employees_currentFunction | null;
  functions: GetWeekTransferRequest_weekTransferRequest_employees_functions[];
  departments: GetWeekTransferRequest_weekTransferRequest_employees_departments[];
  absences: GetWeekTransferRequest_weekTransferRequest_employees_absences[];
  leaves: GetWeekTransferRequest_weekTransferRequest_employees_leaves[];
  remarks: GetWeekTransferRequest_weekTransferRequest_employees_remarks[];
  hotelReservations: GetWeekTransferRequest_weekTransferRequest_employees_hotelReservations[];
  currentHotelReservation: GetWeekTransferRequest_weekTransferRequest_employees_currentHotelReservation | null;
  employments: GetWeekTransferRequest_weekTransferRequest_employees_employments[];
  tempAgency: GetWeekTransferRequest_weekTransferRequest_employees_tempAgency | null;
}

export interface GetWeekTransferRequest_weekTransferRequest {
  __typename: "WeekTransferRequest";
  id: string;
  status: WeekTransferRequestStatus;
  amountOfHours: number;
  user: GetWeekTransferRequest_weekTransferRequest_user;
  weekFrom: GetWeekTransferRequest_weekTransferRequest_weekFrom;
  weekTo: GetWeekTransferRequest_weekTransferRequest_weekTo;
  logs: GetWeekTransferRequest_weekTransferRequest_logs[];
  employees: GetWeekTransferRequest_weekTransferRequest_employees[];
  createdAt: any;
}

export interface GetWeekTransferRequest {
  weekTransferRequest: GetWeekTransferRequest_weekTransferRequest;
}

export interface GetWeekTransferRequestVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReviewWeekTransferRequest
// ====================================================

export interface ReviewWeekTransferRequest_reviewWeekTransferRequest {
  __typename: "WeekTransferRequest";
  id: string;
}

export interface ReviewWeekTransferRequest {
  /**
   *  Accept a transfer request, attaching the employees to be transferred 
   */
  reviewWeekTransferRequest: ReviewWeekTransferRequest_reviewWeekTransferRequest | null;
}

export interface ReviewWeekTransferRequestVariables {
  id: string;
  employees: string[];
  remarks?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterWeekRemark
// ====================================================

export interface AlterWeekRemark_alterWeekRemark_week_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface AlterWeekRemark_alterWeekRemark_week_remarks {
  __typename: "WeekRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: AlterWeekRemark_alterWeekRemark_week_remarks_createdUser;
}

export interface AlterWeekRemark_alterWeekRemark_week {
  __typename: "Week";
  id: string;
  remarks: AlterWeekRemark_alterWeekRemark_week_remarks[];
}

export interface AlterWeekRemark_alterWeekRemark {
  __typename: "WeekRemark";
  id: string;
  remark: string;
  week: AlterWeekRemark_alterWeekRemark_week;
}

export interface AlterWeekRemark {
  alterWeekRemark: AlterWeekRemark_alterWeekRemark | null;
}

export interface AlterWeekRemarkVariables {
  id?: string | null;
  weekId: string;
  remark: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteWeekRemark
// ====================================================

export interface DeleteWeekRemark_deleteWeekRemark {
  __typename: "WeekRemark";
  id: string;
}

export interface DeleteWeekRemark {
  deleteWeekRemark: DeleteWeekRemark_deleteWeekRemark | null;
}

export interface DeleteWeekRemarkVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTempAgencies
// ====================================================

export interface GetTempAgencies_tempAgencies {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface GetTempAgencies {
  /**
   *  Get all temp agencies 
   */
  tempAgencies: GetTempAgencies_tempAgencies[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportWeekTempEmployments
// ====================================================

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_departments_department {
  __typename: "Department";
  id: string;
  area: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_departments_department_area | null;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_departments_department;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_remarks_createdUser;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation_bed_room_section;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation_bed_room;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation_bed;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentFunction | null;
  functions: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_functions[];
  departments: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_departments[];
  absences: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_absences[];
  leaves: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_leaves[];
  remarks: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_remarks[];
  hotelReservations: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_hotelReservations[];
  currentHotelReservation: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_currentHotelReservation | null;
  employments: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_employments[];
  tempAgency: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee_tempAgency | null;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments_employee;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments_logs {
  __typename: "WeekLog";
  id: string;
  type: string;
  hours: number;
  comment: string;
  createdAt: any;
}

export interface ImportWeekTempEmployments_importWeekTempEmployments {
  __typename: "Week";
  id: string;
  hoursSupply: number;
  tempEmployments: ImportWeekTempEmployments_importWeekTempEmployments_tempEmployments[];
  logs: ImportWeekTempEmployments_importWeekTempEmployments_logs[];
}

export interface ImportWeekTempEmployments {
  /**
   *  Imports the provided employements as temp workers 
   */
  importWeekTempEmployments: ImportWeekTempEmployments_importWeekTempEmployments[];
}

export interface ImportWeekTempEmploymentsVariables {
  weekId: string;
  tempAgencyId: string;
  temps: WeekTempEmployments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportHousingTempEmployments
// ====================================================

export interface ImportHousingTempEmployments_importHousingTempEmployments {
  __typename: "Employee";
  id: string;
  number: number;
  name: string;
  needsHousing: boolean;
}

export interface ImportHousingTempEmployments {
  /**
   *  Imports the provided employements as temp workers with housing need 
   */
  importHousingTempEmployments: ImportHousingTempEmployments_importHousingTempEmployments[];
}

export interface ImportHousingTempEmploymentsVariables {
  tempAgencyId: string;
  temps: HousingTempEmployments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterWeekBudget
// ====================================================

export interface AlterWeekBudget_alterWeekBudget {
  __typename: "Week";
  id: string;
  hoursDemand: number;
  budget: number;
}

export interface AlterWeekBudget {
  /**
   * Alter the week budget.
   */
  alterWeekBudget: AlterWeekBudget_alterWeekBudget;
}

export interface AlterWeekBudgetVariables {
  id: string;
  budget: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterWeekFTE
// ====================================================

export interface AlterWeekFTE_alterWeekFTE {
  __typename: "Week";
  id: string;
  hoursSupply: number;
  hoursFTE: number;
}

export interface AlterWeekFTE {
  alterWeekFTE: AlterWeekFTE_alterWeekFTE;
}

export interface AlterWeekFTEVariables {
  id: string;
  hours: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterWeekLock
// ====================================================

export interface AlterWeekLock_alterWeekLock_lockedUser {
  __typename: "User";
  name: string;
}

export interface AlterWeekLock_alterWeekLock {
  __typename: "Week";
  id: string;
  isLocked: boolean;
  lockedAt: any | null;
  lockedUser: AlterWeekLock_alterWeekLock_lockedUser | null;
}

export interface AlterWeekLock {
  alterWeekLock: AlterWeekLock_alterWeekLock;
}

export interface AlterWeekLockVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWeeks
// ====================================================

export interface GetWeeks_weeks_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of total available items.
   */
  total: number;
  /**
   * Index of the last available page.
   */
  lastPage: number;
  /**
   * Index of the current page.
   */
  currentPage: number;
}

export interface GetWeeks_weeks_data_lockedUser {
  __typename: "User";
  name: string;
}

export interface GetWeeks_weeks_data_area {
  __typename: "Area";
  division: string;
  location: string;
}

export interface GetWeeks_weeks_data_forecasts {
  __typename: "WeekForecast";
  state: WeekForecastState;
}

export interface GetWeeks_weeks_data_employments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  abbreviation: string;
  name: string;
}

export interface GetWeeks_weeks_data_employments_employee {
  __typename: "Employee";
  id: string;
  tempAgency: GetWeeks_weeks_data_employments_employee_tempAgency | null;
}

export interface GetWeeks_weeks_data_employments {
  __typename: "Employment";
  id: string;
  employee: GetWeeks_weeks_data_employments_employee;
}

export interface GetWeeks_weeks_data_tempEmployments_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  abbreviation: string;
  name: string;
}

export interface GetWeeks_weeks_data_tempEmployments_employee {
  __typename: "Employee";
  id: string;
  tempAgency: GetWeeks_weeks_data_tempEmployments_employee_tempAgency | null;
}

export interface GetWeeks_weeks_data_tempEmployments {
  __typename: "Employment";
  id: string;
  employee: GetWeeks_weeks_data_tempEmployments_employee;
}

export interface GetWeeks_weeks_data_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetWeeks_weeks_data_remarks {
  __typename: "WeekRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: GetWeeks_weeks_data_remarks_createdUser;
}

export interface GetWeeks_weeks_data {
  __typename: "Week";
  id: string;
  year: number;
  number: number;
  hoursSupply: number;
  hoursDemand: number;
  hoursDemandCosts: number;
  hoursFTE: number;
  forecastRate: number;
  budget: number;
  startAt: any;
  endAt: any;
  isLocked: boolean;
  lockedAt: any | null;
  hasActivities: boolean;
  hasOpenForecastingTasks: boolean;
  lockedUser: GetWeeks_weeks_data_lockedUser | null;
  area: GetWeeks_weeks_data_area;
  forecasts: GetWeeks_weeks_data_forecasts[];
  employments: GetWeeks_weeks_data_employments[];
  tempEmployments: GetWeeks_weeks_data_tempEmployments[];
  remarks: GetWeeks_weeks_data_remarks[];
}

export interface GetWeeks_weeks {
  __typename: "WeekPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: GetWeeks_weeks_paginatorInfo;
  /**
   * A list of Week items.
   */
  data: GetWeeks_weeks_data[];
}

export interface GetWeeks {
  /**
   *  Paginate weeks 
   */
  weeks: GetWeeks_weeks | null;
}

export interface GetWeeksVariables {
  areaId: string;
  yearMonth: string;
  page: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWeekLogs
// ====================================================

export interface GetWeekLogs_weekLogs_data {
  __typename: "WeekLog";
  id: string;
  type: string;
  hours: number;
  comment: string;
  createdAt: any;
}

export interface GetWeekLogs_weekLogs {
  __typename: "WeekLogPaginator";
  /**
   * A list of WeekLog items.
   */
  data: GetWeekLogs_weekLogs_data[];
}

export interface GetWeekLogs {
  /**
   *  Paginate week logs 
   */
  weekLogs: GetWeekLogs_weekLogs | null;
}

export interface GetWeekLogsVariables {
  weekId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUser
// ====================================================

export interface GetUser_me_defaultArea {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetUser_me {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  permissions: string[];
  mfaEnabled: boolean;
  defaultArea: GetUser_me_defaultArea | null;
}

export interface GetUser_areas_data {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetUser_areas {
  __typename: "AreaPaginator";
  /**
   * A list of Area items.
   */
  data: GetUser_areas_data[];
}

export interface GetUser_myAreas_data {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetUser_myAreas {
  __typename: "AreaPaginator";
  /**
   * A list of Area items.
   */
  data: GetUser_myAreas_data[];
}

export interface GetUser {
  /**
   *  Get the current user 
   */
  me: GetUser_me | null;
  /**
   *  Paginate areas 
   */
  areas: GetUser_areas | null;
  /**
   *  Paginate areas 
   */
  myAreas: GetUser_myAreas | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWeekBasicsById
// ====================================================

export interface GetWeekBasicsById_weekById_area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

export interface GetWeekBasicsById_weekById {
  __typename: "Week";
  id: string;
  year: number;
  number: number;
  area: GetWeekBasicsById_weekById_area;
}

export interface GetWeekBasicsById {
  /**
   *  Get a specific week 
   */
  weekById: GetWeekBasicsById_weekById | null;
}

export interface GetWeekBasicsByIdVariables {
  weekId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNotifications
// ====================================================

export interface GetNotifications_me_notifications_data {
  __typename: "NotificationData";
  key: string;
  value: string | null;
}

export interface GetNotifications_me_notifications {
  __typename: "Notification";
  id: string;
  notificationType: string;
  type: string | null;
  text: string | null;
  data: GetNotifications_me_notifications_data[];
  dueAt: any | null;
  readAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface GetNotifications_me {
  __typename: "User";
  notifications: GetNotifications_me_notifications[];
}

export interface GetNotifications {
  /**
   *  Get the current user 
   */
  me: GetNotifications_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeFunction
// ====================================================

export interface EmployeeFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface EmployeeFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: EmployeeFunction_code;
  description: string;
  startedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeAbsence
// ====================================================

export interface EmployeeAbsence {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeLeave
// ====================================================

export interface EmployeeLeave {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeDepartment
// ====================================================

export interface EmployeeDepartment_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface EmployeeDepartment_department {
  __typename: "Department";
  id: string;
  area: EmployeeDepartment_department_area | null;
}

export interface EmployeeDepartment {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: EmployeeDepartment_department;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeRemark
// ====================================================

export interface EmployeeRemark_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface EmployeeRemark {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: EmployeeRemark_createdUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Employee
// ====================================================

export interface Employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface Employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: Employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface Employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface Employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: Employee_functions_code;
  description: string;
  startedAt: any;
}

export interface Employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface Employee_departments_department {
  __typename: "Department";
  id: string;
  area: Employee_departments_department_area | null;
}

export interface Employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: Employee_departments_department;
}

export interface Employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface Employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface Employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface Employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: Employee_remarks_createdUser;
}

export interface Employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface Employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface Employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: Employee_currentHotelReservation_bed_room_section_hotel;
}

export interface Employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: Employee_currentHotelReservation_bed_room_section;
}

export interface Employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: Employee_currentHotelReservation_bed_room;
}

export interface Employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: Employee_currentHotelReservation_bed;
}

export interface Employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface Employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface Employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: Employee_currentFunction | null;
  functions: Employee_functions[];
  departments: Employee_departments[];
  absences: Employee_absences[];
  leaves: Employee_leaves[];
  remarks: Employee_remarks[];
  hotelReservations: Employee_hotelReservations[];
  currentHotelReservation: Employee_currentHotelReservation | null;
  employments: Employee_employments[];
  tempAgency: Employee_tempAgency | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Employment
// ====================================================

export interface Employment_employee_currentFunction_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface Employment_employee_currentFunction {
  __typename: "EmployeeFunction";
  id: string;
  code: Employment_employee_currentFunction_code;
  description: string;
  startedAt: any;
}

export interface Employment_employee_functions_code {
  __typename: "EmployeeFunctionCode";
  description: string;
  isProduction: boolean;
}

export interface Employment_employee_functions {
  __typename: "EmployeeFunction";
  id: string;
  code: Employment_employee_functions_code;
  description: string;
  startedAt: any;
}

export interface Employment_employee_departments_department_area {
  __typename: "Area";
  id: string;
  location: string;
  division: string;
}

export interface Employment_employee_departments_department {
  __typename: "Department";
  id: string;
  area: Employment_employee_departments_department_area | null;
}

export interface Employment_employee_departments {
  __typename: "EmployeeDepartment";
  id: string;
  startedAt: any;
  department: Employment_employee_departments_department;
}

export interface Employment_employee_absences {
  __typename: "EmployeeAbsence";
  id: string;
  dossier: string;
  startedAt: any;
  endedAt: any | null;
}

export interface Employment_employee_leaves {
  __typename: "EmployeeLeave";
  id: string;
  status: string;
  startedAt: any;
  endedAt: any | null;
}

export interface Employment_employee_remarks_createdUser {
  __typename: "User";
  id: string;
  name: string;
}

export interface Employment_employee_remarks {
  __typename: "EmployeeRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdUser: Employment_employee_remarks_createdUser;
}

export interface Employment_employee_hotelReservations {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
}

export interface Employment_employee_currentHotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface Employment_employee_currentHotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: Employment_employee_currentHotelReservation_bed_room_section_hotel;
}

export interface Employment_employee_currentHotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: Employment_employee_currentHotelReservation_bed_room_section;
}

export interface Employment_employee_currentHotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: Employment_employee_currentHotelReservation_bed_room;
}

export interface Employment_employee_currentHotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  bed: Employment_employee_currentHotelReservation_bed;
}

export interface Employment_employee_employments {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
}

export interface Employment_employee_tempAgency {
  __typename: "TempAgency";
  id: string;
  name: string;
  abbreviation: string;
}

export interface Employment_employee {
  __typename: "Employee";
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  number: number;
  marked: boolean;
  applicant: boolean | null;
  needsHousing: boolean;
  nationality: string | null;
  gender: string | null;
  age: number | null;
  type: EmployeeType | null;
  costCenter: EmployeeCostCenter | null;
  currentFunction: Employment_employee_currentFunction | null;
  functions: Employment_employee_functions[];
  departments: Employment_employee_departments[];
  absences: Employment_employee_absences[];
  leaves: Employment_employee_leaves[];
  remarks: Employment_employee_remarks[];
  hotelReservations: Employment_employee_hotelReservations[];
  currentHotelReservation: Employment_employee_currentHotelReservation | null;
  employments: Employment_employee_employments[];
  tempAgency: Employment_employee_tempAgency | null;
}

export interface Employment {
  __typename: "Employment";
  id: string;
  startedAt: any;
  endedAt: any | null;
  employee: Employment_employee;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HotelRoomRemark
// ====================================================

export interface HotelRoomRemark_createdBy {
  __typename: "User";
  id: string;
  name: string;
}

export interface HotelRoomRemark_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
}

export interface HotelRoomRemark_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: HotelRoomRemark_room_section;
}

export interface HotelRoomRemark {
  __typename: "HotelRoomRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdBy: HotelRoomRemark_createdBy;
  room: HotelRoomRemark_room;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HotelRoom
// ====================================================

export interface HotelRoom_remarks_createdBy {
  __typename: "User";
  id: string;
  name: string;
}

export interface HotelRoom_remarks_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
}

export interface HotelRoom_remarks_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: HotelRoom_remarks_room_section;
}

export interface HotelRoom_remarks {
  __typename: "HotelRoomRemark";
  id: string;
  remark: string;
  createdAt: any;
  createdBy: HotelRoom_remarks_createdBy;
  room: HotelRoom_remarks_room;
}

export interface HotelRoom {
  __typename: "HotelRoom";
  id: string;
  name: string;
  remarks: HotelRoom_remarks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HotelSection
// ====================================================

export interface HotelSection_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface HotelSection {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: HotelSection_hotel;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Hotel
// ====================================================

export interface Hotel_sections_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface Hotel_sections {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: Hotel_sections_hotel;
}

export interface Hotel {
  __typename: "Hotel";
  id: string;
  name: string;
  sections: Hotel_sections[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HotelReservation
// ====================================================

export interface HotelReservation_bed_room_section_hotel {
  __typename: "Hotel";
  id: string;
  name: string;
}

export interface HotelReservation_bed_room_section {
  __typename: "HotelSection";
  id: string;
  name: string;
  hotel: HotelReservation_bed_room_section_hotel;
}

export interface HotelReservation_bed_room {
  __typename: "HotelRoom";
  id: string;
  name: string;
  section: HotelReservation_bed_room_section;
}

export interface HotelReservation_bed {
  __typename: "HotelBed";
  id: string;
  room: HotelReservation_bed_room;
}

export interface HotelReservation_employee {
  __typename: "Employee";
  id: string;
  name: string;
  number: number;
  nationality: string | null;
}

export interface HotelReservation {
  __typename: "HotelReservation";
  id: string;
  startAt: any;
  endAt: any | null;
  inProgressAt: any | null;
  completedAt: any | null;
  state: HotelReservationState;
  bed: HotelReservation_bed;
  employee: HotelReservation_employee;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Area
// ====================================================

export interface Area {
  __typename: "Area";
  id: string;
  division: string;
  location: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EmployeeCostCenter {
  PERMANENT = "PERMANENT",
  VARIABLE = "VARIABLE",
}

export enum EmployeeOrderColumn {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  NUMBER = "NUMBER",
}

export enum EmployeeType {
  CAO = "CAO",
  NONCAO = "NONCAO",
  TEMP = "TEMP",
}

export enum Gender {
  F = "F",
  M = "M",
}

/**
 * Represents the state of the reservation
 */
export enum HotelReservationState {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  PLANNED = "PLANNED",
}

/**
 * Directions for ordering a list of records.
 */
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum WeekForecastState {
  FINAL = "FINAL",
  ROLLING = "ROLLING",
}

export enum WeekTransferRequestStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Pending = "Pending",
  Reviewed = "Reviewed",
}

/**
 * Fields required for altering a reservation
 */
export interface AlterHotelReservationInput {
  id: string;
  bed: HotelBedBelongsTo;
  startAt: any;
  endAt?: any | null;
}

export interface EmployeeBelongsTo {
  connect: string;
}

/**
 * Estimation input for the ForecastEstimationsInput type (for the upsertForecastEstimations mutation).
 */
export interface ForecastEstimationInput {
  amount: number;
  norm: number;
  estimateMonday?: number | null;
  estimateTuesday?: number | null;
  estimateWednesday?: number | null;
  estimateThursday?: number | null;
  estimateFriday?: number | null;
  estimateSaturday?: number | null;
  estimateSunday?: number | null;
  comments?: string | null;
  typeActivity: string;
}

/**
 * Input for the upsertForecastEstimations mutation.
 */
export interface ForecastEstimationsInput {
  week: string;
  state: WeekForecastState;
  estimations: ForecastEstimationInput[];
}

/**
 * Input for connecting a bed
 */
export interface HotelBedBelongsTo {
  connect: string;
}

/**
 * Fields required for creating a bed
 */
export interface HotelBedInput {
  number: number;
  room: HotelRoomBelongsTo;
}

/**
 * Input for connecting a hotel
 */
export interface HotelBelongsTo {
  connect: string;
}

/**
 * Fields required for creating a hotel
 */
export interface HotelInput {
  name: string;
}

/**
 * Fields required for creating a reservation
 */
export interface HotelReservationInput {
  startAt: any;
  endAt?: any | null;
  bed: HotelBedBelongsTo;
  employee: EmployeeBelongsTo;
}

/**
 * Input for connecting a room
 */
export interface HotelRoomBelongsTo {
  connect: string;
}

/**
 * Input for connecting a category
 */
export interface HotelRoomCategoryBelongsTo {
  connect: string;
}

/**
 * Fields required for creating a room
 */
export interface HotelRoomInput {
  name: string;
  section: HotelSectionBelongsTo;
  category: HotelRoomCategoryBelongsTo;
}

/**
 * Fields required for creating a room remark
 */
export interface HotelRoomRemarkInput {
  remark: string;
  room: HotelRoomBelongsTo;
}

/**
 * Input for connecting a section
 */
export interface HotelSectionBelongsTo {
  connect: string;
}

/**
 * Fields required for creating a section
 */
export interface HotelSectionInput {
  name: string;
  hotel: HotelBelongsTo;
}

export interface HousingTempEmployments {
  employeeNumber: number;
  name: string;
}

/**
 * Order by clause for Query.employees.orderBy.
 */
export interface QueryEmployeesOrderByOrderByClause {
  column: EmployeeOrderColumn;
  order: SortOrder;
}

export interface WeekTempEmployments {
  employeeNumber: number;
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
