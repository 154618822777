import { observer } from 'mobx-react';
import React, { useContext, useMemo, useRef } from 'react';
import {
  AlterHotelRoom,
  AlterHotelRoomVariables,
  CreateHotelBed,
  CreateHotelBedVariables,
  DeleteHotelBed,
  DeleteHotelBedVariables,
  GetHotelSection_hotelSection,
  GetHotelSection_hotelSection_rooms,
  GetHotelSection_hotelSection_rooms_beds,
  GetHotelWeeks_hotelWeeks
} from '../../../types/GensonGRM';
import Modal from '../../modal/Modal';
import BedOverview from './bed/BedOverview';
import HotelRoomContext from './HotelRoomContext';
import HotelRoomDetailsModal from './details/HotelRoomDetailsModal';
import HotelRoomRemarkModal from './remarks/HotelRoomRemarkModal';
import HotelRoomDeleteModal from './HotelRoomDeleteModal';
import HotelRoomModal from './HotelRoomModal';
import { gql, useMutation } from '@apollo/client';
import { HotelRoom } from '../../../store/apollo/fragments/Fragments';
import { CREATE_HOTEL_BED, DELETE_HOTEL_BED, GET_HOTEL_SECTION_DATA } from '../bedplanner/BedplannerOverview';
import { StoreContext } from '../../../store/StoreContext';
import getDateFromWeekNumber from '../../../util/getDateFromWeekNumber';

export interface RoomOverviewProps {
  section: GetHotelSection_hotelSection;
  room: GetHotelSection_hotelSection_rooms;
  week: GetHotelWeeks_hotelWeeks;
  onPlan: (bed: GetHotelSection_hotelSection_rooms_beds) => void;
}

export const ALTER_HOTEL_ROOM = gql`
  mutation AlterHotelRoom(
      $id: ID!
      $name: String!
      $category: HotelRoomCategoryBelongsTo!
  ) {
    alterHotelRoom(
        id: $id
        name: $name
        category: $category
    ) {
      ...HotelRoom
    }
  }
  
  ${HotelRoom}
`;

const RoomOverview: React.FC<RoomOverviewProps> = observer((props) => {
  const store = useContext(StoreContext);
  const currentYear = new Date().getFullYear();

  const refRoomModal = useRef<Modal>(null);
  const refDetailsModal = useRef<Modal>(null);
  const refRemarkModal = useRef<Modal>(null);

  const refDeleteModal = useRef<Modal>(null);
  const [alterHotelRoom] = useMutation<AlterHotelRoom, AlterHotelRoomVariables>(ALTER_HOTEL_ROOM);
  const [createHotelBed] = useMutation<CreateHotelBed, CreateHotelBedVariables>(CREATE_HOTEL_BED);
  const [deleteHotelBed] = useMutation<DeleteHotelBed, DeleteHotelBedVariables>(DELETE_HOTEL_BED);

  const beds = useMemo(() => props.room.beds
      .filter(bed => (!bed.deletedAt || bed.deletedAt > props.week.startAt) && bed.createdAt < props.week.endAt),
    [props.room.beds]
  );

  return (
    <div className={`room-overview`}>
      <div className="row align-items-center room-overview-header">
        <div className="col-auto">
          <b>{props.room.name}</b>
        </div>
        <div className="col" />
        <div className="col-auto">
          {beds.filter(bed => (bed.reservations?.data.length ?? 0) === 0).length}/{beds.length}
        </div>
      </div>
      <div className="row">
        <div className="col room-overview-content" onClick={e => {
          refDetailsModal.current?.open();
        }}>
          <div className="room-overview-beds">
            {beds.map(bed => <BedOverview key={bed.id} isDeleted={!!bed.deletedAt} bed={bed} onPlan={props.onPlan} />)}
          </div>
          <div className="room-overview-footer">
            <div className="row align-items-center">
              {props.room.remarks.length > 0 && (<div className="col-auto room-overview-notes">
                <div className="icon">
                  <button className="btn" type="button" aria-expanded="false">
                    <span className="material-icons">mode_comment</span>
                    <span className="remarks-count">{props.room.remarks.length}</span>
                  </button>
                </div>
              </div>)}
              <div className="col" />
              <div className="col-auto room-overview-menu">
                <HotelRoomContext
                  room={props.room}
                  onDetails={() => refDetailsModal.current?.open() }
                  onRemark={() => refRemarkModal.current?.open() }
                  onUpdate={() => refRoomModal.current?.open() }
                  onDelete={() => refDeleteModal.current?.open() } />
              </div>
            </div>
          </div>
        </div>
      </div>

      <HotelRoomModal
        ref={refRoomModal}
        hotelSection={props.section}
        hotelRoom={props.room}
        onSave={async (roomName, roomBeds, roomCategory) => {
          await alterHotelRoom({
            variables: {
              id: props.room.id,
              name: roomName,
              category: {
                connect: roomCategory,
              },
            }
          });

          const refetchQueries = [{
            query: GET_HOTEL_SECTION_DATA,
            variables: {
              id: props.room.section.id,
              start: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1),
              end: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7)
            }
          }];
          const activeBedCount = props.room.beds.filter(bed => !bed.deletedAt).length;

          if (roomBeds > activeBedCount) {
            for (let bedIndex = activeBedCount; bedIndex < roomBeds; bedIndex++) {
              await createHotelBed({
                variables: {
                  input: {
                    number: bedIndex + 1,
                    room: {
                      connect: props.room.id
                    }
                  }
                },
                refetchQueries: (bedIndex === (roomBeds - 1)) ? refetchQueries : [],
                awaitRefetchQueries: true
              });
            }
          } else if (roomBeds < activeBedCount) {
            for (let bedIndex = activeBedCount; bedIndex > roomBeds; bedIndex--) {
              const bedToDelete = beds[bedIndex - 1];
              await deleteHotelBed({
                variables: {
                  id: bedToDelete.id
                },
                refetchQueries: (bedIndex === (roomBeds + 1)) ? refetchQueries : [],
                awaitRefetchQueries: true
              })
            }
          }
        }}
      />

      <HotelRoomDetailsModal
        ref={refDetailsModal}
        room={props.room}
        beds={beds}
        onAddRemark={() => refRemarkModal.current?.open() }
        onPlan={props.onPlan}
      />

      <HotelRoomRemarkModal ref={refRemarkModal} room={props.room} />
      <HotelRoomDeleteModal ref={refDeleteModal} room={props.room} />
    </div>
  );
});

export default RoomOverview;
