import React, { useContext, useMemo, useRef } from 'react';
import {
  AlterEmployeeRemark,
  AlterEmployeeRemarkVariables,
  DeleteWeekTempEmployment,
  DeleteWeekTempEmploymentVariables,
  GetWeekschemaById_weekById,
  GetWeekschemaById_weekById_employments,
  ToggleEmployeeMark,
  ToggleEmployeeMarkVariables,
  UndoWeekException,
  UndoWeekExceptionVariables,
  Employee as EmployeeType,
  ToggleEmployeeHousingVariables,
  ToggleEmployeeHousing
} from '../../types/GensonGRM';
import { StateContext } from '../../store/StateContext';
import EmployeeContext from "./EmployeeContext";
import { observer } from "mobx-react";
import EmployeeRemarkModal from './EmployeeRemarkModal';
import { gql, useMutation } from '@apollo/client';
import { EmployeeRemark as EmployeeRemarkFragment, Employment } from '../../store/apollo/fragments/Fragments';
import { GET_WEEK_LOGS } from '../weeklog/WeekLogItems';
import WeekDeleteTemp from '../week/temps/WeekDeleteTemp';
import TransferUndoModal from '../transfer/TransferUndoModal';
import EmployeeDeleteRemark from './remark/EmployeeDeleteRemark';
import EmployeeBody from './EmployeeBody';
import { StoreContext } from '../../store/StoreContext';
import getDatesInRange from '../../util/getDatesInRange';

export interface EmployeeProps {
  employee: EmployeeType;
  employment?: GetWeekschemaById_weekById_employments;
  week?: GetWeekschemaById_weekById;
  employmentStart?: Date;

  transferId?: string;
  isOnLeave?: boolean;
  isAbsent?: boolean;
  isTransferIn?: boolean;
  isTransferOut?: boolean;
  weekLocked?: boolean;
  allowTempDelete?: boolean;
  isPlanboard?: boolean;
  static?: boolean;
}

export const ALTER_EMPLOYEE_REMARK = gql`
  mutation AlterEmployeeRemark($id: ID, $employeeId: ID!, $remark: String!) {
    alterEmployeeRemark(id: $id, employeeId: $employeeId, remark: $remark) {
      id
      remark
      employee {
        id
        name
        remarks {
          ...EmployeeRemark
        }
      }
    }
  }

  ${EmployeeRemarkFragment}
`;

const TOGGLE_EMPLOYEE_MARK = gql`
  mutation ToggleEmployeeMark($employeeId: ID!) {
    toggleEmployeeMark(employeeId: $employeeId) {
      id
      name
      marked
    }
  }
`;

const TOGGLE_EMPLOYEE_HOUSING = gql`
  mutation ToggleEmployeeHousing($employeeId: ID!) {
    toggleEmployeeHousing(employeeId: $employeeId) {
      id
      name
      needsHousing
    }
  }
`;

const DELETE_WEEK_TEMP_EMPLOYMENT = gql`
  mutation DeleteWeekTempEmployment($weekId: ID!, $employmentId: ID!) {
    deleteWeekTempEmployment(weekId: $weekId, employmentId: $employmentId) {
      id
      hoursSupply
      tempEmployments {
        ...Employment
      }
    }
  }

  ${Employment}
`;

const UNDO_WEEK_EXCEPTION = gql`
  mutation UndoWeekException($id: ID!) {
    undoWeekException(id: $id) {
      id
      number
      year
      hoursSupply
      hoursDemand
      hoursDemandCosts
      hoursFTE
      budget
      transferredIn {
        id
        weekFrom {
          id
          area {
            id
            division
            location
          }
        }
        employment {
          ...Employment
        }
      }
      transferredOut {
        id
        weekTo {
          id
          area {
            id
            division
            location
          }
        }
        employment {
          ...Employment
        }
      }
      logs {
        id
        type
        hours
        comment
        createdAt
      }
    }
  }

  ${Employment}
`;

const Employee: React.FC<EmployeeProps> = observer((props) => {
  const store = useContext(StoreContext);
  const state = useContext(StateContext);
  const refRemark = useRef<EmployeeRemarkModal | null>(null);
  const refDeleteRemark = useRef<EmployeeDeleteRemark | null>(null);
  const refWeekDeleteTemp = useRef<WeekDeleteTemp | null>(null);
  const refUndoTransfer = useRef<TransferUndoModal | null>(null);

  const [alterEmployeeRemark] = useMutation<AlterEmployeeRemark, AlterEmployeeRemarkVariables>(ALTER_EMPLOYEE_REMARK);
  const [toggleEmployeeMark] = useMutation<ToggleEmployeeMark, ToggleEmployeeMarkVariables>(TOGGLE_EMPLOYEE_MARK);
  const [toggleEmployeeHousing] = useMutation<ToggleEmployeeHousing, ToggleEmployeeHousingVariables>(TOGGLE_EMPLOYEE_HOUSING);
  const [undoWeekException] = useMutation<UndoWeekException, UndoWeekExceptionVariables>(UNDO_WEEK_EXCEPTION);
  const [deleteWeekTempEmployment] = useMutation<DeleteWeekTempEmployment, DeleteWeekTempEmploymentVariables>(DELETE_WEEK_TEMP_EMPLOYMENT);

  const isOnLeave = useMemo(() => {
    if (props.isOnLeave !== undefined) return props.isOnLeave;
    return props.isOnLeave || props.employee.leaves.some(a =>
      new Date(a.startedAt).getTime() < new Date().getTime() && (!a.endedAt || new Date(a.endedAt).getTime() > new Date().getTime())
    );
  }, [props.isOnLeave, props.employee.leaves]);

  const isAbsent = useMemo(() => {
    if (props.isAbsent !== undefined) return props.isAbsent;
    return props.isAbsent || props.employee.absences.some(a =>
      new Date(a.startedAt).getTime() < new Date().getTime() && (!a.endedAt || new Date(a.endedAt).getTime() > new Date().getTime())
    );
  }, [props.isAbsent, props.employee.absences]);

  const icon = useMemo(() => {
    if (isOnLeave) {
      return 'card_travel';
    }

    if (isAbsent) {
      return 'local_hospital';
    }

    if (props.isTransferIn) {
      return 'person_add';
    }

    if (props.isTransferOut) {
      return 'person_remove';
    }

    return 'person';
  }, [isOnLeave, isAbsent, props.isTransferIn, props.isTransferOut]);

  const isExpanded = props.employee.id === state.employeeOpenId;

  const isContractRunningOut = useMemo(() => {
    const dateTwoWeeks = new Date();
    dateTwoWeeks.setDate(dateTwoWeeks.getDate() + 14);

    return props.employment && props.employment.endedAt &&
      ((props.week && props.employment.endedAt <= props.week.endAt) || (!props.week && new Date(props.employment.endedAt).getTime() <= dateTwoWeeks.getTime()));

  }, [props.employment, props.week]);

  const isContractPending = useMemo(() => {
    const currentDate = new Date();
    return props.employment && new Date(props.employment.startedAt).getTime() > currentDate.getTime();
  }, [props.employment]);

  const functionLocation = props.isPlanboard
    ? (props.employee.departments.length > 0)
      ? [...props.employee.departments].sort((a, b) => a.startedAt > b.startedAt ? -1 : 1)[0].department.area?.location
      : ``
    : `${props.employee.currentFunction?.description} ${props.employee.applicant ? "(sollicitant)" : ""}`;

  return (
    <div className={`employee ${isExpanded ? 'in' : ''}`}>
      <div className="col-auto  align-items-center employee-header"
        onClick={() => !props.static && state.setEmployeeOpenId(isExpanded ? null : props.employee.id)}>
        <div className="employee-icon">
          {props.employee.marked
            ? <div className="material-icons marked">priority_high</div>
            : (props.employee.tempAgency
              ? <div className="agency-name">
                <abbr title={props.employee.tempAgency.name}>{props.employee.tempAgency.abbreviation}</abbr>
              </div>
              : (isContractRunningOut
                ? <div data-balloon-pos="up" aria-label="Contract loopt af/is beëindigd" className="material-icons marked">{icon}</div>
                : (isContractPending
                  ? <div data-balloon-pos="up" aria-label="Contract gaat (binnenkort) in" className="material-icons marked-green">{icon}</div>
                  : <div data-balloon-pos="up" className="material-icons">{icon}</div>)
              )
            )}
        </div>

        <div className="col employee-name">
          {props.employee.nationality && (<div className={`flag:${props.employee.nationality}`} />)} {props.employee.name}
          <span>
            {props.employee.number}{props.employee.currentFunction && functionLocation && ` - ${functionLocation}`}
            {props.employmentStart && ` - Start: ${state.dateFormatter.format(new Date(props.employmentStart))}`}
          </span>
        </div>

        {props.week && (
          <div className="employee-availability">
            {getDatesInRange(props.week.startAt, props.week.endAt).map(d => {
              const hasLeave = props.employee.leaves.some(l => new Date(l.startedAt) <= d && (!l.endedAt || new Date(l.endedAt) >= d));
              const hasAbsence = props.employee.absences.some(l => new Date(l.startedAt) <= d && (!l.endedAt || new Date(l.endedAt) >= d));
              const isWeekend = d.getDay() === 0 || d.getDay() === 6;
              const className = isWeekend ? "day-weekend" : (hasLeave || hasAbsence || props.isTransferOut) ? "day-unavailable" : "day-available";
              return (<div className={`day ${className}`} title={d.toDateString()} />);
            })}
          </div>
        )}

        {!props.static && props.employee.needsHousing && store.user?.can('housing-view') && (
          <div className="employee-icon">
            <div className="material-icons" title="Huisvestingsbehoevend">house</div>
          </div>
        )}

        {!props.static && (
          <>
            <div className="col-auto align-items-center">
              <EmployeeContext
                employee={props.employment ? props.employment.employee : props.employee}
                isTransfer={props.isTransferIn || props.isTransferOut}
                weekLocked={props.weekLocked}
                allowTempDelete={props.allowTempDelete}
                onRemark={() => {
                  refRemark.current?.open();
                }}
                onMark={() => {
                  toggleEmployeeMark({
                    variables: {
                      employeeId: props.employee.id
                    },
                    refetchQueries: [
                      'GetPlanboard'
                    ]
                  })
                }}
                onHousing={() => {
                  toggleEmployeeHousing({
                    variables: {
                      employeeId: props.employee.id
                    },
                    refetchQueries: [
                      'GetPlanboard'
                    ]
                  })
                }}
                onUndoTransfer={() => {
                  if (!props.transferId) return;

                  refUndoTransfer.current?.open();
                }}
                onDeleteTemp={() => {
                  if (!props.week) return;

                  refWeekDeleteTemp.current?.open();
                }} />
            </div>

            <div className="material-icons">
              {isExpanded ? 'expand_less' : 'expand_more'}
            </div>

            {props.children}
          </>
        )}

      </div>

      {!props.static && (
        <>
          <EmployeeBody employee={props.employee} employment={props.employment} />

          <EmployeeRemarkModal ref={refRemark} employee={props.employee} onSave={(remarkText, remark) => {
            alterEmployeeRemark({
              variables: {
                id: remark?.id,
                employeeId: props.employee.id,
                remark: remarkText
              }
            })
          }} />

          <EmployeeDeleteRemark ref={refDeleteRemark} employee={props.employee} />

          <WeekDeleteTemp ref={refWeekDeleteTemp} week={props.week} employment={props.employment} onDelete={() => {
            if (!props.week || !props.employment) return;

            deleteWeekTempEmployment({
              variables: {
                weekId: props.week.id,
                employmentId: props.employment.id
              },
              refetchQueries: [{
                query: GET_WEEK_LOGS,
                variables: {
                  weekId: props.week.id
                }
              }],
              awaitRefetchQueries: true
            });
          }} />

          <TransferUndoModal ref={refUndoTransfer} week={props.week} employment={props.employment} onUndo={() => {
            if (!props.transferId || !props.week) return;

            undoWeekException({
              variables: {
                id: props.transferId
              },
              refetchQueries: [{
                query: GET_WEEK_LOGS,
                variables: {
                  weekId: props.week.id
                }
              }],
              awaitRefetchQueries: true
            });
          }}
          />
        </>
      )}
    </div>
  )
});

export default Employee;
