import { observer } from 'mobx-react';
import React, { useContext, useRef } from 'react';
import { StateContext } from '../../../../store/StateContext';
import { StoreContext } from '../../../../store/StoreContext';
import { GetHotelSection_hotelSection_rooms_beds, GetHotelSection_hotelSection_rooms_beds_reservations_data, HotelReservation } from '../../../../types/GensonGRM';
import getDateFromWeekNumber from '../../../../util/getDateFromWeekNumber';
import EmployeeBody from '../../../employee/EmployeeBody';
import Modal from '../../../modal/Modal';
import HotelReservationDeleteModal from '../../hotel/reservation/HotelReservationDeleteModal';
import HotelReservationUpdateModal from '../../hotel/reservation/HotelReservationUpdateModal';
import HotelRoomReservationsBody from '../details/HotelRoomReservationsBody';
import ReservationContext from '../details/ReservationContext';

export interface BedOverviewProps {
  bed: GetHotelSection_hotelSection_rooms_beds;
  isDeleted: boolean;

  displayReservationsList?: boolean;
  reservations?: HotelReservation[];
  displayEmployee?: boolean;
  onPlan: (bed: GetHotelSection_hotelSection_rooms_beds) => void;
}

const BedOverview: React.FC<BedOverviewProps> = observer((props) => {
  const store = useContext(StoreContext);
  const state = useContext(StateContext);

  const refUpdateReservationModal = useRef<Modal>(null);
  const refDeleteReservationModal = useRef<Modal>(null);
  const currentYear = new Date().getFullYear();

  const reservation: GetHotelSection_hotelSection_rooms_beds_reservations_data | undefined = props.bed.reservations?.data[0];

  const currentWeekStart = getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1);
  const currentWeekEnd = getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7);
  const stateChanging = reservation && (
    (new Date(reservation.startAt) >= currentWeekStart && new Date(reservation.startAt) < currentWeekEnd) ||
    (reservation.endAt && new Date(reservation.endAt) >= currentWeekStart && new Date(reservation.endAt) < currentWeekEnd)
  );

  return (
    <div className="employee in">
      <div className={`bed-overview ${reservation || props.displayReservationsList ? "occupied" : "available"} ${props.isDeleted ? "deleted" : ""}`}
      title={props.isDeleted ? `Bed verwijderd vanaf ${state.dateFormatter.format(new Date(props.bed.deletedAt))}` : undefined}
        onClick={(e) => {
          if (reservation || props.isDeleted) {
            return;
          }

          e.stopPropagation();
          props.onPlan(props.bed);
        }}>
        <div className="row row-space-between">
          <div className="col-auto col-flex">
            <div className="col-auto col-icon">
              <span className="material-icons">hotel</span>
            </div>
            <div className="col-auto col-status">
              #{props.bed.number} - {props.displayReservationsList
                ? "Bed"
                : reservation
                  ? (<>{reservation.employee.nationality && <div className={`flag:${reservation.employee.nationality}`} />} <span>{reservation.employee.name}</span></>)
                  : "Beschikbaar"
              }
            </div>
          </div>

          {!props.displayReservationsList && (
            <div className="col-auto col-flex col-flex-info">
              {(props.displayEmployee && reservation) && (
                <>
                  <div className="col-auto col-reservation-span">
                    {state.dateFormatter.format(new Date(reservation.startAt))}{` t/m `}{reservation.endAt ? state.dateFormatter.format(new Date(reservation.endAt)) : "onbepaald"}
                  </div>
                  <div className="col-auto col-context">
                    <ReservationContext
                      reservation={reservation}
                      onAlterReservation={() => refUpdateReservationModal.current?.open()}
                      onDeleteReservation={() => refDeleteReservationModal.current?.open()} />
                  </div>
                </>
              )}

              {(reservation && !props.displayEmployee) && (
                <div className={`col-auto col-info ${stateChanging && "bed-state-changing"}`}>
                  t/m {reservation.endAt ? `${state.dateFormatter.format(new Date(reservation.endAt))}` : "onbepaald"}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {(props.displayEmployee && !props.displayReservationsList && reservation) && (
        <>
          <EmployeeBody employee={reservation.employee} displayAge />
          <HotelReservationUpdateModal ref={refUpdateReservationModal} bed={props.bed} reservation={reservation} />
          <HotelReservationDeleteModal ref={refDeleteReservationModal} reservation={reservation} />
        </>
      )}

      {props.displayReservationsList && props.reservations && (
        <HotelRoomReservationsBody reservations={props.reservations} />
      )}
    </div>
  );
});

export default BedOverview;
